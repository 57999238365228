import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import usePlaces from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useFormContext } from 'react-hook-form';
import { Input } from '../input';

interface AddressComponent {
  long_name: string;
  types: string[];
}

interface IAddressFields {
  street?: string;
  apartment?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
interface IAutoCompleteLocationProps {
  fieldNames?: IAddressFields;
  defaultValues?: Partial<Record<keyof IAddressFields, string>>;
}

const AutoCompleteLocationComponent: React.FC<IAutoCompleteLocationProps> = ({
  fieldNames = {
    street: 'street',
    apartment: 'apartment',
    city: 'city',
    state: 'state',
    zipCode: 'zipCode',
  },
  defaultValues,
}) => {
  const [showPredictions, setShowPredictions] = useState(false);
  const { getValues, setValue } = useFormContext();
  const [address, setAddress] = useState<IAddressFields>({
    street: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const { placesService, placePredictions, getPlacePredictions } = usePlaces({
    debounce: 300,
    language: 'en',
    apiKey: process.env.NEXT_PUBLIC_MAPS_API,
    options: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
  });

  // triggers places prediction on change of the street field
  const onQueryChange = (event) => {
    getPlacePredictions({
      input: event.target.value,
    });
    setAddress((address) => ({ ...address, street: event.target.value }));
    setShowPredictions(true);
  };

  // gets diff address components
  const getAddressComponentValue = (addressComponents: AddressComponent[], type: string) => {
    const component = addressComponents.find((component) => component.types.includes(type));
    return component ? component.long_name : '';
  };

  // extracts street name of the prediction
  const getStreetName = (addressComponents: AddressComponent[]): string => {
    const streetNumberComponent = addressComponents.find((component) =>
      component.types.includes('street_number'),
    );
    const streetNumber = streetNumberComponent?.long_name || '';

    const streetNameComponent = addressComponents.find((component) =>
      component.types.includes('route'),
    );
    const streetName = streetNameComponent?.long_name || '';

    return streetNumber + ' ' + streetName;
  };

  const updateFormState = useCallback(
    (data: IAddressFields) => {
      setValue(fieldNames.apartment, data.apartment, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(fieldNames.street, data.street, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(fieldNames.state, data.state, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(fieldNames.city, data.city, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(fieldNames.zipCode, data.zipCode, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [
      fieldNames.apartment,
      fieldNames.city,
      fieldNames.state,
      fieldNames.street,
      fieldNames.zipCode,
      setValue,
    ],
  );

  // selector on click of predicted addresses
  const selectAddress = useCallback(
    (placeId: string) => {
      placesService.getDetails(
        {
          placeId,
        },
        (place) => {
          const addressComponents = place?.address_components ?? [];
          const street = getStreetName(addressComponents);
          const city = getAddressComponentValue(addressComponents, 'locality');
          const state = getAddressComponentValue(addressComponents, 'administrative_area_level_1');
          const zipCode = getAddressComponentValue(addressComponents, 'postal_code');

          setAddress((prev) => {
            const data = { ...prev, street, city, state, zipCode };

            updateFormState(data);

            return data;
          });
          setShowPredictions(false);
        },
      );
    },
    [placesService, updateFormState],
  );

  // renders the predictions
  const predictions = useMemo(
    () =>
      placePredictions.length > 0 &&
      showPredictions && (
        <div className="absolute z-10 w-5/6 mt-1 bg-white-100 border border-gray-300 rounded-md shadow-lg overflow-y-auto max-h-[250px]">
          {placePredictions.map((prediction) => (
            <div
              key={prediction.place_id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                selectAddress(prediction?.place_id);
              }}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      ),
    [placePredictions, selectAddress, showPredictions],
  );

  // handles change of the input boxes after prediction selected (manual inputs)
  const handleChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress((prevAddress) => {
      const data = { ...prevAddress, [event.target.name]: event.target.value };
      updateFormState(data);
      return data;
    });
  };

  useEffect(() => {
    setAddress({
      street: defaultValues?.street || getValues(fieldNames.street) || '',
      apartment: defaultValues?.apartment || getValues(fieldNames.apartment) || '',
      city: defaultValues?.city || getValues(fieldNames.city) || '',
      state: defaultValues?.state || getValues(fieldNames.state) || '',
      zipCode: defaultValues?.zipCode || getValues(fieldNames.zipCode) || '',
    });
  }, [
    defaultValues?.apartment,
    defaultValues?.city,
    defaultValues?.state,
    defaultValues?.street,
    defaultValues?.zipCode,
    fieldNames.apartment,
    fieldNames.city,
    fieldNames.state,
    fieldNames.street,
    fieldNames.zipCode,
    getValues,
  ]);

  return (<>
    <section>
      <Input
        label="Street"
        placeholder="Street and number, Apt, Unit, etc."
        className="w-full"
        name={fieldNames.street}
        validations={{ required: true, pattern: /[a-zA-Z]{2,30}/ }}
        onChange={onQueryChange}
        autoComplete="true"
        value={address.street}
      />
      {/* predictions list */}
      {predictions}
    </section>
    <section className="grid gap-4 w-full sm:grid-cols-2">
      <Input
        label="Apartment / Suite / Unit"
        wrapperClassName="w-full"
        placeholder="Enter Apartment / Suite / Unit Number"
        className="w-full"
        name={fieldNames.apartment}
        autoComplete="true"
        value={address.apartment}
        onChange={handleChange}
      />
      <Input
        wrapperClassName="w-full"
        label="City"
        placeholder="City"
        className="w-full"
        name={fieldNames.city}
        autoComplete="true"
        validations={{ required: true, pattern: /[a-zA-Z]{2,30}/ }}
        value={address.city}
        onChange={handleChange}
      />
    </section>
    <section className="grid gap-4 w-full sm:grid-cols-2">
      <Input
        label="State"
        wrapperClassName="w-full"
        placeholder="State"
        className="w-full"
        name={fieldNames.state}
        validations={{ required: true, pattern: /[a-zA-Z]{2,30}/ }}
        autoComplete="true"
        value={address.state}
        onChange={handleChange}
      />
      <Input
        label="Zip Code"
        placeholder="Zip"
        wrapperClassName="w-full"
        className="w-full"
        name={fieldNames.zipCode}
        validations={{ required: true, pattern: /[0-9]{5,5}/ }}
        autoComplete="true"
        value={address.zipCode}
        onChange={handleChange}
        mask="99999"
      />
    </section>
  </>);
};

export const AutoCompleteLocation = memo(AutoCompleteLocationComponent);
