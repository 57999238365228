import { commonRoutes } from '@utils';
import clsx from 'clsx';
import { AdditionalResourcesIcon } from 'iconComponents/AdditionalResourcesIcon';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { BankAccountsIcon } from 'iconComponents/BankAccountsIcon';
import { FinancesIcon } from 'iconComponents/FinancesIcon';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import { FinancialLiteracyIcon } from 'iconComponents/FinancialLiteracyIcon';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { RewardsIcon } from 'iconComponents/RewardsIcon';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { SettingsMenuIcon } from 'iconComponents/SettingsMenuIcon';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { SignOutMenuIcon } from 'iconComponents/SignOutMenuIcon';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { SummaryIcon } from 'iconComponents/SummaryIcon';
import EmergencyIcon from '@mui/icons-material/Emergency';
import { MessagesIcon } from 'iconComponents/MessagesIcon';
import { useRouter } from 'next/router';
import { MouseEventHandler } from 'react';
import { MenuLink } from '../menu-link';
import Image from 'next/image';
import { useSidebarContext } from 'contexts/sidebar-context';

export const BuyerLayoutMenu = ({
  welcomTitle,
  logout,
  hide,
  toggleMenu,
}: {
  welcomTitle: string;
  logout: Function;
  hide?: boolean;
  toggleMenu: MouseEventHandler;
}) => {
  const router = useRouter();
  const { setIsSectionSelected } = useSidebarContext();

  return (
    <div
      className={clsx('mt-7 mx-auto lg:mt-0 lg:ml-10 w-[272px] p-8 !pb-10 mb-5', {
        hidden: hide,
      })}
    >
      <ul className="text-[15px] font-normal">
        <li className="mb-[50px] font-semibold ml-4">
          {/* Welcome, <span className="opacity-[0.7]">{welcomTitle}!</span> */}
          <Image src="/images/MHP_Lockup_Mint.png" alt="logo" width={133} height={55} />
        </li>
        <MenuLink
          icon={<EmergencyIcon />}
          href={commonRoutes.buyer.auth.summary}
          toggleMenu={toggleMenu}
        >
          Summary
        </MenuLink>
        <MenuLink
          icon={<FinancialLiteracyIcon />}
          href={commonRoutes.buyer.auth.financialliteracy}
          toggleMenu={() => {
            setIsSectionSelected(false);
          }}
        >
          Financial Literacy
        </MenuLink>
        <MenuLink
          icon={<WalletOutlinedIcon />}
          href={commonRoutes.buyer.auth.finances}
          toggleMenu={toggleMenu}
        >
          Finances
        </MenuLink>
        <MenuLink
          icon={<AccountBalanceOutlinedIcon />}
          href={commonRoutes.buyer.auth.accounts}
          toggleMenu={toggleMenu}
        >
          Accounts
        </MenuLink>
        <MenuLink
          icon={<EmojiEventsOutlinedIcon />}
          href={commonRoutes.buyer.auth.rewards}
          toggleMenu={toggleMenu}
        >
          Rewards
        </MenuLink>
        <MenuLink
          icon={<MenuBookOutlinedIcon />}
          href={commonRoutes.buyer.auth.additionalresources}
          toggleMenu={toggleMenu}
        >
          Additional Resources
        </MenuLink>
        <MenuLink
          icon={<MessagesIcon />}
          href={commonRoutes.buyer.auth.messages}
          toggleMenu={toggleMenu}
        >
          Messages
        </MenuLink>
        <MenuLink
          icon={<SettingsOutlinedIcon />}
          href={commonRoutes.buyer.auth.settings}
          toggleMenu={toggleMenu}
        >
          Settings
        </MenuLink>

        <li className="text-red">
          <button
            type="button"
            className="p-[15px] flex gap-3 items-center hover:opacity-50"
            onClick={() => {
              logout();
              router.push('/user/get-started');
            }}
          >
            <ArrowCircleRightOutlinedIcon />
            SIGN OUT
          </button>
        </li>
      </ul>
    </div>
  );
};
