import { BuyerLayout } from '@components/layout/buyer-layout';
import { MonthPicker, Empty } from '@components';
import { MonthlySpending } from './monthly-spending';
import { TransactionHistory } from './transaction-history';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CashFlow } from './cash-flow';
import { IncomeExpensesChart } from './income-expenses-chart';
import { ApiService } from '@services/Api';
import { useQuery } from 'react-query';
import Link from 'next/link';
import { commonRoutes } from '@utils';

const maxDate = dayjs().subtract(1, 'month');

export const Finances = () => {
  const [selectedDate, setSelectedDate] = useState(maxDate);

  // NOTE: since this whole finances page calculations are dependant
  // on transactions. We'll check if any transactions exist and show "Empty" accordingly
  const { data: dataExists } = useQuery(
    ['buyer-plaid-items-transactions-exists'],
    async () => {
      const resp = await ApiService.getBuyerPlaidTransactions({
        page: 1,
        pageSize: 1,
      });

      return !!resp.data.total;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );

  return (
    (<BuyerLayout headerContainerClassName="block">
      <div className="mt-16 mx-3.5 sm:ml-11 sm:mr-7">
        {dataExists && (
          <>
            <div className="flex mb-6">
              <h1 className="font-medium text-2xl text-gray-900 my-auto mr-3">Finances</h1>
              <MonthPicker
                maxDate={maxDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20">
              <div>
                <MonthlySpending selectedMonth={selectedDate} />
                <div className="hidden md:block">
                  <TransactionHistory />
                </div>
              </div>

              <div>
                <div className="mb-8">
                  <CashFlow selectedMonth={selectedDate} />
                </div>
                <IncomeExpensesChart />
              </div>

              <div className="block md:hidden mt-12">
                <TransactionHistory />
              </div>
            </div>
          </>
        )}

        {dataExists === false && (
          <Empty
            main="Not enough bank accounts linked"
            flavor={
              <>
                That&apos;s strange, how about{' '}
                <Link
                  href={commonRoutes.buyer.auth.accounts}
                  className="text-[#10B395] hover:underline">
                  adding one
                </Link>
                ?
              </>
            }
          />
        )}
      </div>
    </BuyerLayout>)
  );
};
