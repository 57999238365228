import { RequestBuilder } from '../../lib/RequestBuilder';
import { useUserStore } from '@hooks';
import { AxiosRequestHeaders } from 'axios';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-api-key': process.env.NEXT_PUBLIC_SERVER_API_KEY || '',
  'Access-Control-Allow-Origin': '*',
};

export const apiRequestClient = () => {
  const additionalConfig = {
    headers: defaultHeaders,
  };

  const accessToken = useUserStore?.getState?.()?.accessToken;
  // Adding the access token to the request if it exists
  if (accessToken) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    additionalConfig.headers = { ...additionalConfig.headers, ...headers };
  }

  return new RequestBuilder(process.env.NEXT_PUBLIC_API_URL, additionalConfig);
};
