import { apiRequestClient } from '../client';
import { AuthenticationPartialSignupForm, EAuthenticationType, User } from '../types';
import { Buyer, EUserType } from './../types';

// ------------------------- // Common // ------------------------- //
// /**
//  * Starts the authentication flow by phone
//  */
// export const startAuthenticationByPhone = (body: Pick<User, 'phone'>) => {
//   return apiRequestClient()
//     .method('post')
//     .append('auth/phone')
//     .setData(body)
//     .build<{ authenticationId: string; type: 'signin' | 'signup' }>();
// };
/**
 * Get linkedin email using access token
 */
// old code
// export const getSocialLoginProviderEmailUsingAccessToken = (body: { accessToken: string }) => {
//   return apiRequestClient().method('post').append('auth/social-login/email').setData(body).build<{
//     elements: string;
//   }>();
// };

export const getSocialLoginProviderEmailUsingAccessToken = (body: {
  authCode: string;
  redirectURI: string;
}) => {
  return apiRequestClient().method('post').append('auth/social-login/email').setData(body).build<{
    elements: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    accessToken?: string;
    code?: string;
    error?: string;
  }>();
};

/**
 * Starts the authentication flow by Social
 */
export const startSocialLoginAuthenticationByEmail = (body: {
  firstName: string;
  lastName: string;
  provider: string;
  email: string;
  userType: EUserType;
  type: EAuthenticationType;
  referredBy;
}) => {
  return apiRequestClient().method('post').append('auth/social-login').setData(body).build<{
    firstName: string;
    lastName: string;
    authenticationId: string;
    type: EAuthenticationType;
    email: string;
    authType: string;
    provider: string;
    accessToken: string;
    userType: EUserType;
    partialSignupForm: AuthenticationPartialSignupForm;
    user: User;
    buyer: Buyer;
  }>();
};

/**
 * Starts the authentication flow by email & password
 */
export const startAuthenticationByEmailAndPassword = (body: {
  email: string;
  password: string;
  userType: EUserType;
  type: EAuthenticationType;
  referredBy?: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/sign-in-with-email-and-password')
    .setData(body)
    .build<{
      authenticationId: string;
      type: EAuthenticationType;
      email: string;
      password: string;
      userType: EUserType;
      user: User;
      accessToken: string;
    }>();
};

/**
 * Starts the reset password flow by email
 */
export const startResetPasswordAuthenticationByEmail = (body: {
  email: string;
  userType: EUserType;
  type: EAuthenticationType;
  referredBy?: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/reset-password-by-email')
    .setData(body)
    .build<{
      authenticationId: string;
      type: EAuthenticationType;
      email: string;
      userType: EUserType;
    }>();
};

/**
 * Reset password OTP verification flow
 */
export const resetPasswordOTPVerification = (body: {
  authenticationId: string;
  userType: EUserType;
  code: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/reset-password-otp-verification')
    .setData(body)
    .build<{
      authenticationId: string;
      type: EAuthenticationType;
      email: string;
      userType: EUserType;
      userVerified: Boolean;
    }>();
};

/**
 * Reset password OTP verification flow
 */
export const resetPasswordUpdation = (body: {
  authenticationId: string;
  email: string;
  password: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/reset-password-updation')
    .setData(body)
    .build<{
      accessToken: string;
      user: User;
      buyer?: Buyer;
    }>();
};

/**
 * Starts the authentication flow by email
 */
export const startAuthenticationByEmail = (body: {
  email: string;
  userType: EUserType;
  type: EAuthenticationType;
  referredBy?: string;
}) => {
  return apiRequestClient().method('post').append('auth/email').setData(body).build<{
    authenticationId: string;
    type: EAuthenticationType;
    email: string;
    userType: EUserType;
  }>();
};

export const createVerificationCode = (body: { phone: string }) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/phone-verification')
    .setData(body)
    .build<{
      success: boolean;
      status: string;
      error: string;
      message: string;
      statusCode: number;
    }>(); // Define the expected response type
};

export const verifyPhoneCode = (body: {
  email: string;
  verificationCode: string;
  phone: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('/buyer/phone-code-verification')
    .setData(body)
    .build<{
      success: boolean;
      status: string;
      error: string;
      message: string;
      statusCode: number;
    }>(); // Define the expected response type
};

/**
 * Starts the authentication flow by partnerId
 */
export type SignUpVerifyPartnerCodeResponse = {
  partnerCodeStatus: boolean;
  associateIdStatus: boolean;
};
export const signUpVerifyPartnerCode = (body: {
  partnerCode: string;
  lenderCode: string;
  associateId: string;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/sign-up/verify-partner-code')
    .setData(body)
    .build<SignUpVerifyPartnerCodeResponse>();
};

// ------------------------- // Sign In // ------------------------- //
/**
 * Verifies the authentication code, and signs in the user
 */
export const signIn = (body: { authenticationId: string; userType: EUserType; code: string }) => {
  return apiRequestClient()
    .method('post')
    .append('auth/sign-in')
    .setData(body)
    .build<{ accessToken: string; user: User; buyer?: Buyer }>();
};

// ------------------------- // Sign Up // ------------------------- //
/**
 * Verifies the authentication code, if no error, then the user may proceed to the next step
 */
export type SignUpVerifyCodeResponse = {
  authenticationId: string;
  email: string;
  partialSignupForm: AuthenticationPartialSignupForm;
  userType: EUserType;
};
export const signUpVerifyCode = (body: {
  authenticationId: string;
  code: string;
  userType: EUserType;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/sign-up/verify')
    .setData(body)
    .build<SignUpVerifyCodeResponse>();
};

/**
 * Saves the partial signups form data, to be used later in a different device, if needed
 */
export const savePartialSignUpForm = (body: {
  authenticationId: string;
  email: string;
  userType: EUserType;
  partialSignupForm: AuthenticationPartialSignupForm;
}) => {
  return apiRequestClient()
    .method('post')
    .append('auth/sign-up/save-partial')
    .setData(body)
    .build<void>();
};

// ------------------------- // Verification // ------------------------- //
/**
 * Starts the verification flow and sends a code to the chosen type for the authenticated user
 */
export const sendVerificationCode = (body: { type: 'email' | 'phone' }) => {
  return apiRequestClient()
    .method('post')
    .append('auth/send-verification-code')
    .setData(body)
    .build<{ verificationId: string }>();
};

/**
 * Resends the authentication code
 */
export const resendCode = (body: {
  id: string;
  type: 'authentication' | 'verification';
  emailCodeType?: 'sign-up' | 'reset';
}) => {
  return apiRequestClient().method('post').append('auth/resend-code').setData(body).build<void>();
};

/**
 * Verify the authentication code
 */
export const verifyCode = (body: { authenticationId: string; code: string }) => {
  return apiRequestClient().method('post').append('auth/verify').setData(body).build<void>();
};

/**
 * Starts the authentication flow by SSO v2 firebase
 */
export const startSSOLogin = (body: {
  token: string;
  userType: EUserType;
  type: EAuthenticationType;
  provider: 'google' | 'apple';
  referredBy: string;
  firstName?: string;
  lastName?: string;
}) => {
  return apiRequestClient().method('post').append('auth/sso').setData(body).build<{
    firstName: string;
    lastName: string;
    authenticationId: string;
    type: EAuthenticationType;
    email: string;
    authType: string;
    provider: string;
    accessToken: string;
    userType: EUserType;
    partialSignupForm: AuthenticationPartialSignupForm;
    user: User;
    buyer: Buyer;
  }>();
};
