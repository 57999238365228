import { gradeInfo } from '@components';
import clsx from 'clsx';
import Image from "next/image";
import CreditCardIcon from '@icons/credit-card.svg';
import LoadingIcon from '@icons/loading-icon.svg';
import CalculatorIcon from '@icons/calculator.svg';
import PieChartIcon from '@icons/pie-chart.svg';
import IncomeIcon from '@icons/income.svg';
import CircularCoin from '@icons/circle-dollar-sign.svg';
import EditBox from '@icons/edit-box.svg';
import WalletIcon from '@icons/wallet-cards.svg';
import HandCoin from '@icons/hand-coins.svg';
import { DashboardItem } from '../dashboard-item';

import {
  useDashboardModal,
  useDebtsQuery,
  useIncomeQuery,
  useSavingsQuery,
  useScoreProps,
} from '@hooks';
import { commonRoutes, toUSD } from '@utils';
import { useBuyerCreditScore } from '@hooks/use-buyer-credit-score';
import { useAnotherAmount } from '@components/custom-input/time-pills';
import { DashboardItemInfo } from '../dashboard-item/dashboard-item-info';
import { Box } from '@mui/material';

export const DashboardBreakdown = ({
  userHousePriceGoal,
  expectedPurchaseMonthsPeriod,
}: {
  userHousePriceGoal: number;
  expectedPurchaseMonthsPeriod: number;
}) => {
  const { setCurrentModal } = useDashboardModal();

  const incomesQuery = useIncomeQuery();
  const savingsQuery = useSavingsQuery();
  const debtsQuery = useDebtsQuery();

  const buyerScore = useBuyerCreditScore();

  const { otherAmountSelection } = useAnotherAmount();

  const { debtToIncomeProps, grossIncomeProps, creditScoreProps, downpaymentProps } =
    useScoreProps();

  const goalText = `${toUSD(userHousePriceGoal)} (${
    otherAmountSelection === 'years'
      ? `${(expectedPurchaseMonthsPeriod / 12).toFixed(0)} year${
          expectedPurchaseMonthsPeriod === 12 ? '' : 's'
        }`
      : `${expectedPurchaseMonthsPeriod} months`
  })`;

  const RouteIcon = () => {
    return (
      <Image
        alt="badge img"
        width={34}
        height={46}
        className="filter brightness-90 opacity-50"
        src="/icons/Bell.svg"
         />
    );
  };

  return (
    (<section className=" card-class rounded-2xl !pb-0">
      {/* <div className="flex items-center gap-2 mb-4">
        <h1
          className="text-[16px] font-md leading-5"
          style={{ fontFamily: 'PlusJakartaSans', fontWeight: 600 }}
        >
          Next Steps
        </h1>
        <div className="w-[21px] h-[21px] flex justify-center items-center rounded-full bg-red-600">
          <span className="text-white">!</span>
        </div>
      </div> */}
      <div id='step3' >
      <DashboardItem
        Icon={WalletIcon}
        iconColor="#FFFFFF"
        cardColorClassName="bg-[#CDFBE1]"
        title="Credit Score"
        description="This is a rating based on your credit history to determine your borrower's creditworthiness."
        actionTitle="GENERATE "
        canClickAction={buyerScore?.isError}
        onCardClick={() => setCurrentModal('creditScoreGrade')}
        onActionClick={() => setCurrentModal('createCreditReportUser')}
        {...creditScoreProps}
      
      >
        {!buyerScore?.isError && (
          <DashboardItemInfo
            valueLabel={creditScoreProps.valueLabel}
            title="Credit score"
            gradeColor={gradeInfo[creditScoreProps?.grade]?.color}
          />
        )}
      </DashboardItem>
      </div>
      <DashboardItem
        Icon={CreditCardIcon}
        iconColor="#0AAA82"
        cardColorClassName="bg-[#CDFBE1]"
        title="Down Payment"
        description="This is a portion of the purchase price paid upfront by you, typically expressed as a percentage of total price."
        routeButtonText="See what you are eligible for with our partner."
        routeIcon={<RouteIcon />}
        href={commonRoutes.buyer.auth.additionalresources}
        actionTitle="ADD SAVINGS"
        canClickAction={savingsQuery?.data?.length === 0}
        onCardClick={() => setCurrentModal('downpaymentGrade')}
        onActionClick={() => setCurrentModal('downpaymentDetails')}
        {...downpaymentProps}
      >
        {savingsQuery?.data?.length !== 0 && downpaymentProps?.valueLabel && (
          <DashboardItemInfo
            valueLabel={downpaymentProps.valueLabel}
            title="Downpayment"
            gradeColor={gradeInfo[downpaymentProps?.grade]?.color}
          />
        )}
        <button
          className={clsx('  bg-white w-full  h-[48px]   rounded-full mt-4')}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCurrentModal('editGoal');
          }}
        >
          <div className="flex justify-between items-center px-4 py-3 ">
            <p className="text-[12px] font-medium">My goal</p>
            <div className="text-[12px] font-medium">{goalText}</div>
            <div className="flex gap-2 items-center">
              <Image
                src="/images/pencil.png"
                alt="edit"
                width={16}
                height={16}
                 />
            </div>
          </div>
        </button>
      </DashboardItem>
      <DashboardItem
        Icon={HandCoin}
        iconColor="#FFFFFF"
        cardColorClassName="bg-[#CDFBE1]"
        title="Debt-to-Income (DTI)"
        description="This is a calculation of your total debt payments used to assess your ability to make mortgage payments."
        actionTitle="ADD DEBT"
        canClickAction={debtsQuery?.data?.length === 0}
        onCardClick={() => setCurrentModal('debtToIncomeGrade')}
        onActionClick={() => setCurrentModal('debtToIncomeDetails')}
        {...debtToIncomeProps}
      >
        {debtsQuery?.data?.length !== 0 && debtToIncomeProps?.valueLabel && (
          <DashboardItemInfo
            valueLabel={debtToIncomeProps.valueLabel}
            title="DTI ratio"
            gradeColor={gradeInfo[debtToIncomeProps?.grade]?.color}
          />
        )}
      </DashboardItem>
      <DashboardItem
        Icon={CircularCoin}
        iconColor="#FFFFFF"
        cardColorClassName="bg-[#CDFBE1]"
        title="Income"
        description="This is the amount of money you earn from employment, investments, or other sources. "
        actionTitle="ADD INCOME"
        canClickAction={incomesQuery?.data?.length === 0}
        onCardClick={() => setCurrentModal('incomeGrade')}
        onActionClick={() => setCurrentModal('incomeDetails')}
        {...grossIncomeProps}
      >
        {incomesQuery?.data?.length !== 0 && grossIncomeProps?.valueLabel && (
          <DashboardItemInfo
            valueLabel={grossIncomeProps.valueLabel}
            title="Current income"
            gradeColor={gradeInfo[grossIncomeProps?.grade]?.color}
          />
        )}
      </DashboardItem>
    </section>)
  );
};
