import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuthUser, useIsMounted } from '@hooks';
import { ApiService } from '@services/Api';
import { BuyerLayout } from '../layout/buyer-layout';
import 'react-chat-elements/dist/main.css';
import { MessageType, IChatItemProps } from 'react-chat-elements';
import { io } from 'socket.io-client';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import Image from 'next/image';
import ChatIcon from '@mui/icons-material/Chat';
const messageListReferance = React.createRef();

let newSocket = null;
interface ISelectedAdminUser {
  id: any;
  name: string;
}
interface ISelectedUser {
  id: any;
  name: string;
  adminId: string;
  reciverId: string;
}

const ChatSection = styled(Paper)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  borderLeft: '0px solid red',
});

const BorderRight500 = styled(Box)({
  border: '1px solid #e0e0e0',
  borderRadius: '25px 25px 25px ',
  width: '25%',
  padding: '15px',
  height: '100%',
  overflowY: 'auto',
});

const MessageArea = styled(List)({
  height: 'calc(100vh - 160px)',
  overflowY: 'auto',
  border: '1px solid #e0e0e0',
  borderRadius: '25px',
});

interface IChatItemPropsChild extends IChatItemProps {
  adminId?: string;
  lastMessageTime: string;
  userId: string | number;
  userName: string;
  lastMessage: string;
}
function createThread(
  userId: string | number,
  userName: string,
  messageText: string,
  date: Date,
): IChatItemPropsChild {
  return {
    id: userId,
    avatar: '',
    alt: '',
    title: userName,
    subtitle: messageText,
    date: date,
    unread: 0,
    statusColor: '#AABBAA',
    statusColorType: 'encircle',
    className: '',
    lastMessageTime: '',
    userName: userName,
    lastMessage: messageText,
    userId: userId,
  };
}

interface IMessageType {
  receiverID: string | number;
  senderID: string | number;
  text: string;
  date: Date;
  userSenderName: string;
  userReceiverName: string;
  displayName: string;
}
function createTextMessage(
  receiverID: string | number,
  senderID: string | number,
  text: string,
  date: Date,

  userSenderName: string,
  userReceiverName: string,
  displayName: string,
): IMessageType {
  return {
    receiverID,
    senderID,
    text,
    date,
    userSenderName,
    userReceiverName,
    displayName,
  };
}

export const MessagesDashboard = () => {
  const router = useRouter();
  const [filteredThreads, setFilteredThreads] = useState<IChatItemPropsChild[]>([]);

  const [threads, setThreads] = useState<IChatItemPropsChild[]>([]);
  const [messages, setMessages] = useState<IMessageType[]>([]);
  console.log('🚀 ~ MessagesDashboard ~ messages:', messages);
  const [selectedAdminUser, setSelectedAdminUser] = useState<ISelectedAdminUser | null>(null);
  const [inputValue, setInputValue] = useState('');
  const isMounted = useIsMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [socket, setSocket] = useState<any>(null);
  const [adminMsgID, setadminMsgID] = useState<string | number | null>(null);
  const [userId, setUserId] = useState<any | null>(null);
  const [adminUserName, setadminUserName] = useState<any | null>(null);

  //const [userName, setUserName] = useState<any | null>(null);
  const [mostLatestMsg, setMostLatestMsg] = useState<IMessageType>(null);
  //const [latestMessage, setLatestMessage] = useState<any | null>(null);

  const user = useAuthUser();
  const { id, name } = router.query; // Extract 'id' from URL query parameters

  useEffect(() => {
    console.log('rernedering');
    console.log(messages);
    fetchThreads();
    if (newSocket == null && !newSocket?.connected) {
      newSocket = io(`${process.env.NEXT_PUBLIC_API_URL}`, {
        transports: ['websocket'],
      });

      newSocket.on('connect', () => {
        console.log('Connected to WebSocket server');
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from server.');
      });

      newSocket.on('message', (message) => {
        if (message.userId === user.id) {
          console.log('Received message:', message);

          const sendingDate = new Date();
          console.log(messages);
          console.log(threads);
          setMessages((prevMessages) => [
            ...prevMessages,
            // createTextMessage(
            //   message.messageText,
            //   message.isSenderAdmin,
            //   message.isSenderAdmin
            //     ? `${user.firstName} ${user.lastName}`
            //     : "User",
            //   new Date(message.createdAt)
            // ),
            createTextMessage(
              user.id,
              adminMsgID,
              message.messageText,
              sendingDate,
              user.firstName,
              adminUserName,
              message.userName,
            ),
          ]);

          // setMostLatestMsg(
          //   createTextMessage(
          //     adminMsgID,
          //     userId,
          //     message.messageText,
          //     sendingDate,
          //     message.username,
          //     user.firstName,
          //   ),
          // );

          setThreads((prevThreads) => {
            console.log('prevThreads');
            console.log(prevThreads);
            // Create a deep copy of the previous threads
            const newThreads = prevThreads.map((thread) => ({ ...thread }));

            let selectedThread = null;
            let selectedIndex = -1;

            // Find the matching thread and its index
            for (let i = 0; i < newThreads.length; i++) {
              console.log('loop 1');
              console.log(newThreads[i].id);
              console.log(message.userId);

              console.log('loop 1 ends');
              if (newThreads[i].id === message.adminId) {
                selectedThread = { ...newThreads[i] }; // Make a copy of the selected thread to avoid mutation
                selectedIndex = i;
                break; // Exit loop once the thread is found
              }
            }

            // If a matching thread is found, move it to the top
            if (selectedThread !== null) {
              console.log('matched');
              selectedThread.subtitle = message.messageText;
              newThreads.splice(selectedIndex, 1); // Remove the thread from its current position
              newThreads.unshift(selectedThread); // Add the copied thread to the top of the array
            }

            // Return the updated threads array to update the state
            console.log('newThreads');
            console.log(newThreads);
            return newThreads;
          });

          // if (selectedUser && message.userId === selectedUser.id) {

          // }

          // fetchThreads();
        } else {
          console.log('You cant see the message');
        }
      });
    }
  }, []);

  // return () => {
  //   if (newSocket) {
  //     newSocket.close();
  //   }
  // };

  // Create a ref for the message container
  const messageEndRef = useRef<null | HTMLDivElement>(null);

  // Function to scroll to the bottom of the message area

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ block: 'end' });
    }
  }, [messages]);
  // Dependency on messages to scroll every time messages update

  const fetchThreads = async () => {
    console.log('fetching threads');
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/messages/user-sorted?userId=${user.id}`,
        // `${process.env.NEXT_PUBLIC_API_URL}/messages/user-sorted?userId=fec6330b-fb4e-4ab4-a767-2cbfbd4fa231`,
      );
      const result = await response.json();

      // Log the entire response to verify its structure
      console.log('API Response:', result); // Pass only the user ID
      // affiliatedUsersResponse.data is the actual array of users

      let data = result.map((thread) =>
        createThread(thread.adminId || '', thread.adminName, thread.message, new Date(thread.time)),
      );
      data = data.filter((thread) => thread.title != null);
      console.log('data Response:');
      console.log(data);
      setThreads(data);
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  // useEffect(() => {
  //   const newSocket = io(`${process.env.NEXT_PUBLIC_API_URL}`, {
  //     transports: ['websocket'],
  //   });

  //   newSocket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //     setSocket(newSocket);

  //   });

  //   newSocket.on('message', (message) => {
  //     if (message.userId === user.id) {
  //       console.log('Received message:', message);

  //       const sendingDate = new Date();

  //       setMessages((prevMessages) => [
  //         ...prevMessages,
  //         // createTextMessage(
  //         //   message.messageText,
  //         //   message.isSenderAdmin,
  //         //   message.isSenderAdmin
  //         //     ? `${user.firstName} ${user.lastName}`
  //         //     : "User",
  //         //   new Date(message.createdAt)
  //         // ),
  //         createTextMessage(adminMsgID, userId, message.messageText, sendingDate, message.username, user.firstName)

  //       ]);

  //       setMostLatestMsg(
  //         createTextMessage(adminMsgID, userId, message.messageText, sendingDate, message.username, user.firstName)

  //        );

  //        const newThreads = [...threads];

  //        let selectedThread = null;
  //        let selectedIndex = -1;

  //        // Find the matching thread and its index
  //        for (let i = 0; i < newThreads.length; i++) {
  //          if (newThreads[i].id === message.userId) {
  //            selectedThread = newThreads[i];
  //            selectedIndex = i;
  //            break; // Exit loop once the thread is found
  //          }
  //        }

  //        // If a matching thread is found, move it to the top
  //        if (selectedThread !== null) {
  //         selectedThread.subtitle = message.messageText;
  //          newThreads.splice(selectedIndex, 1); // Remove the thread from its current position
  //          newThreads.unshift(selectedThread); // Add the thread to the top of the array
  //        }
  //        // Update the state with the new threads array
  //        setThreads(newThreads);

  //       // if (selectedUser && message.userId === selectedUser.id) {

  //       // }
  //       setThreads((prevThreads) => [
  //         ...prevThreads,
  //         createThread(
  //           user.id,
  //           user.firstName,
  //           message.messageText,
  //           new Date(message.createdAt)
  //         )
  //       ])
  //       // fetchThreads();
  //     } else {
  //       console.log("You cant see the message");
  //     }
  //   });

  //   newSocket.on('disconnect', () => {
  //     console.log('Disconnected from server.');
  //   });

  //   return () => {
  //     if (newSocket) {
  //       newSocket.close();
  //     }
  //   };
  // }, [user.id]);

  // THREADS

  const formatCustomTime = (date: Date) => {
    const d = new Date(date);
    const hours = d.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = d.getMinutes().toString().padStart(2, '0');
    const seconds = d.getSeconds().toString().padStart(2, '0');
    const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

    return `${hours}:${minutes}:${seconds} ${ampm}`;
  };

  // const createTextMessage = (
  //   message: string,
  //   isAdmin: boolean,
  //   adminUserName: string,
  //   time: any,
  // ): MessageType => {
  //   return {
  //     type: 'text',
  //     id: String(Math.random()),
  //     position: 'left',
  //     text: message,
  //     title: isAdmin ? adminUserName : `${user.firstName} ${user.lastName}`,
  //     focus: true,
  //     date: time,
  //     titleColor: '#BBBBBB',
  //     forwarded: false,
  //     replyButton: false,
  //     removeButton: false,
  //     status: 'received',
  //     statusTitle: 'MHP',
  //     notch: false,
  //     copiableDate: true,
  //     retracted: false,
  //     className: '',
  //   };
  // };

  const fetchMessages = async () => {
    if (selectedAdminUser && user) {
      // Ensure both IDs are available
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/messages/conversation?adminId=${selectedAdminUser.id}&userId=${user.id}`,
        );
        const data = await response.json();
        console.log(data);
        // Map the fetched data to match the expected format
        const sendingDate = new Date();
        console.log('data', data);
        // Map the fetched data to match the expected format
        const formattedMessages = data.map((message) =>
          // console.log('qwewqwewqwew',
          //   user.id,
          //             selectedUser.id,
          //             message.message,
          //             sendingDate,
          //             user.firstName,
          //             selectedUser.name
          // ),

          createTextMessage(
            user.id,
            adminMsgID,
            message.message,
            sendingDate,
            user.firstName,
            adminUserName,
            message.userName,
          ),
        );

        setMessages(formattedMessages);
        messageMarkAsRead();
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    }
  };

  const messageMarkAsRead = async () => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/messages/mark-read?senderId=${selectedAdminUser?.id}&receiverId=${user?.id}`,
      );
      // const data = await response.json();

      // setMessages(data);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  useEffect(() => {
    // Fetch messages from the backend API

    fetchMessages();
  }, [adminMsgID, userId]); // Trigger fetching when selectedAdminUser or user changes

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (newSocket && inputValue.trim() !== '' && adminMsgID) {
      // Emit message only once
      const sendingDate = new Date();

      newSocket.emit('message', {
        reciverId: adminMsgID,
        username: user.firstName + ' ' + user.lastName || 'me',
        senderId: user.id,
        text: inputValue,
      });
      console.log('fdsafdsafdsafdsa');
      console.log({
        reciverId: adminMsgID,
        username: user.firstName + ' ' + user.lastName || 'me',
        senderId: user.id,
        text: inputValue,
      });
      // setMostLatestMsg(
      //   createTextMessage(
      //     user.id,
      //     adminMsgID,
      //     inputValue,
      //     sendingDate,
      //     user.firstName,
      //     adminUserName,
      //   ),
      // );

      // // fetchThreads();
      //  threads.forEach((thread) => {
      //   if (thread.id === selectedUser.id) {
      //     thread.subtitle = inputValue;

      //   }
      //  })

      const newThreads = [...threads];

      let selectedThread = null;
      let selectedIndex = -1;

      // Find the matching thread and its index
      for (let i = 0; i < newThreads.length; i++) {
        if (newThreads[i].id === adminMsgID) {
          selectedThread = newThreads[i];
          selectedIndex = i;
          break; // Exit loop once the thread is found
        }
      }

      // If a matching thread is found, move it to the top
      if (selectedThread !== null) {
        selectedThread.subtitle = inputValue;
        newThreads.splice(selectedIndex, 1); // Remove the thread from its current position
        newThreads.unshift(selectedThread); // Add the thread to the top of the array
      }
      // Update the state with the new threads array
      setThreads(newThreads);

      /*end*/
      console.log('user user anme', user.firstName);
      // Update message list in state with only the new message
      setMessages((prevMessages) => [
        ...prevMessages,
        createTextMessage(
          user.id,
          adminMsgID,
          inputValue,
          sendingDate,
          user.firstName,
          adminUserName,
          'me',
        ),
      ]);
      // setThreads((prevThreads) => [
      //   ...prevThreads,
      //   createThread(
      //     adminMsgID,
      //     adminUserName,
      //     inputValue,
      //     sendingDate
      //   )
      // ])

      setInputValue('');
    }
  };

  // useEffect(() => {
  //   const filtered = threads.filter((thread) => {
  //     const title = thread.title ? thread.title.toLowerCase() : ''; // Use an empty string if title is null/undefined
  //     const term = searchTerm ? searchTerm.toLowerCase() : '';

  //     // Exclude threads where thread.id matches user.id
  //     if (thread.id === user.id) {
  //       return false; // Exclude this thread
  //     }

  //     return title.includes(term);
  //   });

  // Sort filtered threads by relevance (you can add additional sorting logic if needed)
  //   const sorted = filtered.sort((a, b) => a.title.localeCompare(b.title));

  //   setFilteredThreads(sorted); // Update the filtered threads state
  // }, [searchTerm, threads, user.id]); // Add user.id as a dependency

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = threads.filter((user) =>
    user.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    if (id) {
      fetchMessages();

      // Directly set the ID or simulate fetching user data
      setadminMsgID(id as string);
      setSelectedAdminUser({ id: id, name: name as string }); // Replace with actual logic if needed
      // Replace with actual logic if needed
    }
  }, [id]);

  return (
    isMounted && (
      <BuyerLayout headerContainerClassName="block p-0 h-full ">
        {/* <Typography variant="h5" className="header-message pb-5">
              Chat
            </Typography> */}
        <ChatSection>
          <BorderRight500>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              onChange={handleSearchChange}
              fullWidth
              style={{ margin: '10px 0' }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(10, 170, 130, 1)',
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: 'rgba(10, 170, 130, 1)',
                  },
                },
              }}
            />
            <Divider />
            <List component={Box}>
              {filteredUsers.length === 0 && (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                    border: '1px solid #e0e0e0',
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    No results
                  </Typography>
                </ListItem>
              )}
              {filteredUsers.map((thread, index) => (
                <ListItem
                  component="button"
                  key={index}
                  onClick={() => {
                    setSelectedAdminUser({ id: thread.id, name: thread.title });
                    setadminMsgID(thread.id);
                    setUserId(user.id);
                    setadminUserName(thread.title);
                  }}
                  sx={{
                    borderBottom: '1px solid #e0e0e0',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          style={{
                            display: 'block',
                            textAlign: 'left',
                          }}
                        >
                          {thread.title}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {/* {selectedUser && selectedAdminUser && selectedAdminUser.id === thread.id
                        ? mostLatestMsg || latestMessage
                        : latestMessage} */}

                          {thread.subtitle}
                        </Typography>
                        <br />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{
                            display: 'block',
                            textAlign: 'right',
                          }}
                        >
                          {new Date(thread.date).toLocaleTimeString()}
                        </Typography>
                      </>
                    }
                    primaryTypographyProps={{
                      align: 'left',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </BorderRight500>
          {!adminMsgID ? (
            <Box className="flex flex-col flex-1 border border-[#e0e0e0] rounded-xl bg-blue-200 p-4">
              <Box className="flex flex-col justify-center items-center h-[70%] text-[#1C1A27] ">
                <ChatIcon sx={{ fontSize: '50px' }} />
                <Typography variant="h6" align="center" >
                  Select a user to chat
                </Typography>
                {/* <Image
                  src="https://mhp-icons.s3.amazonaws.com/mint-lockup.png"
                  width={175}
                  height={62}
                  alt="Select a chat"
                  className="transition-all duration-300"
                /> */}
              </Box>
            </Box>
          ) : (
            <Box sx={{ flex: 1, padding: '20px', height: '100%', overflowY: 'auto' }}>
              <Typography variant="h6" align="center" gutterBottom>
                {selectedAdminUser
                  ? `Chatting with ${selectedAdminUser.name} `
                  : 'Select a user to chat'}
              </Typography>
              <MessageArea>
                {messages?.map((message, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{
                              textAlign: 'left', // Move to right if adminId matches user ID
                              backgroundColor: '#f0f0f0', // Optional: Change background color based on ownership
                              padding: '8px',
                              borderRadius: '12px',
                              display: 'inline-block',
                              maxWidth: '80%',
                            }}
                          >
                            <span
                              style={{
                                display: 'block', // Makes the span a block element
                                borderBottom: '1px solid #0AAA82', // Adds bottom border
                                paddingBottom: '4px', // Adds padding below the title
                                marginBottom: '4px',
                                fontSize: '10px',
                                color: '#0AAA82',
                              }}
                            >
                              {message.displayName}
                            </span>
                            {message.text}
                          </Typography>
                        </>
                      }
                      secondary={
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ textAlign: 'left' }}
                        >
                          {formatCustomTime(message.date)}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
                <div ref={messageEndRef} />
              </MessageArea>

              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="20px"
                gap="20px"
              >
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(10, 170, 130, 1)',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: 'rgba(10, 170, 130, 1)',
                      },
                    },
                  }}
                />
                <Fab
                  aria-label="send"
                  onClick={handleSendMessage}
                  sx={{
                    backgroundColor: 'rgba(10, 170, 130, 1)',
                    '&:hover': {
                      backgroundColor: 'rgba(10, 170, 130, 0.8)',
                    },
                    color: 'white',
                  }}
                >
                  <SendIcon />
                </Fab>
              </Box>
            </Box>
          )}
        </ChatSection>
      </BuyerLayout>
    )
  );
};
