import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface SidebarContextType {
  isSectionSelected: boolean;
  setIsSectionSelected: (selected: boolean) => void;
}

// Create the context
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

// Provider component
export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isSectionSelected, setIsSectionSelected] = useState<boolean>(false);

  return (
    <SidebarContext.Provider value={{ isSectionSelected, setIsSectionSelected }}>
      {children}
    </SidebarContext.Provider>
  );
};

// Custom hook for accessing the context
export const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
};
