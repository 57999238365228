export const DashboardItemInfo = ({
  title,
  gradeColor,
  valueLabel,
}: {
  title: string;
  gradeColor?: string;
  valueLabel: string | number;
}) => (
  <div className="flex flex-col w-full items-center">
    <p className="text-sm text-gray-600 mb-1">{title}</p>
    <p className="text-xl font-medium" style={{ color: gradeColor }}>
      {valueLabel}
    </p>
  </div>
);
