import { Button, CustomInput, ErrorMessage, FormWrapper, Steps } from '@components';
import { useAuthUser, useBuyerSigninInfo } from '@hooks';
import { commonRoutes } from '@utils';
import { TermsModal, useOpenTermsModal } from '@components/modal/terms-modal';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { ApiService, Authentication, EAuthenticationType, EUserType } from '@services/Api';
import { BuyerSocialSignin } from '../social-logins';
import { BuyerSocialSigninV2 } from '@components/social-logins-v2';
import * as gtag from '@lib/gtag';
import { PartnerList } from '@components/partners-list';

export const Step1 = () => {
  const { setBuyerInfo, step, email } = useBuyerSigninInfo();
  const { updateUserInfo, signedIn } = useAuthUser();
  const methods = useForm({ defaultValues: { email, password: '' } });
  const router = useRouter();
  const [submitErrorMsg, setSubmitErrorMsg] = React.useState<any>(false);
  const referredBy = router.isReady && (router.query?.referredBy as string);

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const startAuthenticationMutation = useMutation(
    async (values: Authentication) => {
      const resp = await ApiService.startAuthenticationByEmailAndPassword({
        email: values.email,
        password: values.password,
        userType: EUserType.buyer,
        type: EAuthenticationType.signin,
        referredBy,
      }).catch((error) => {
        setSubmitErrorMsg(true);
        if (error?.data?.error === 'user_not_found') {
          // setSubmitError('Email not found, please sign up');
          throw new Error('Your email or password is incorrect, please try again.');
        }
        if (error?.data?.error === 'email_or_password_invalid') {
          // setSubmitError('The email address or password you entered is invalid');
          throw new Error('The email address or password you entered is invalid');
        }
        throw (
          error?.data?.message ||
          'We encountered an error on our side. Please try again in a few minutes.'
        );
      });

      return resp.data;
    },
    {
      onSuccess: (data) => {
        setBuyerInfo({ authenticationId: null });
        const remappedUserType = data.user.userType === 'buyer' ? 'buyer' : 'partner';

        // if not logged in previously send login event to ga
        if (!signedIn && data.accessToken)
          gtag.event({
            action: 'login',
            category: 'interaction',
            label: 'Login',
            value: {
              user_id: data.user.id,
              provider: 'email-password',
            },
          });

        updateUserInfo({
          ...data.user,
          userType: remappedUserType,
          accessToken: data.accessToken,
          signedIn: true,
          dataSynced: false,
        });
      },
    },
  );

  const { setOpenTermsModal } = useOpenTermsModal();

  const intl = useIntl();

  let submitError = startAuthenticationMutation.error as Error;

  return (
    (<FormWrapper
        onBackClick={() => {
          router.push(commonRoutes.buyer.getStarted);
        }}
        isLoading={startAuthenticationMutation.isLoading}
        onSubmit={methods.handleSubmit((values) => {
          startAuthenticationMutation.mutate(values as Authentication); // Authentication is type of values object data
        })}
        methods={methods}
        maxWidth={null}
        containerClassName="lg:px-0 mb-0 lg:-mt-[31px] xs:px-0"
      >
      <div className="flex scale-90	 h-full bg-white p-5 lg:p-[50px] lg:min-w-[624px] xs:min-h-[500px] mx-auto r-10 ">
        <div className="flex flex-col w-full lg:justify-center">
          <PartnerList />
          <Steps.Title className="lg:font-bolder lg:text-[32px] xs:mb-3 sm:mb-3 md:mb-3 lg:mb-2.5 text-center">
            {intl.formatMessage({ id: 'page.account.welcomeback' })}
          </Steps.Title>
          <p className="text-[15px] text-center text-gray-500 dark:text-gray-400 xs:mb-5 sm:mb-3 md:mb-3 lg:mb-5">
            {intl.formatMessage({ id: 'page.account.signinmessage' })}
          </p>

          <div className=" lg:justify-center w-full mx-auto flex flex-col flex-grow">
            <div className="w-full">
              <CustomInput.Email
                showLabel={true}
                placeholder="your.email@example.com"
                label={'Email Address'}
              />
              <div className="mt-4">
                <CustomInput.Password
                  showLabel={true}
                  placeholder="Enter your password"
                  showIcon={true}
                />
              </div>
              {submitError && <ErrorMessage error={submitError?.message} />}
            </div>
            <div className="mt-auto xs:mt-7 sm:mt-7 md:mt-7 lg:mt-4">
              <Steps.Button>{intl.formatMessage({ id: 'page.account.signin' })}</Steps.Button>
              <div className="flex flex-row text-center items-center gap-1 mt-6 justify-center lg:hidden">
                <p className="xs:text-sm text-sm text-black-400 dark:text-black-400">
                  {intl.formatMessage({ id: 'getstarted.noAccount' })}
                </p>
                <Button
                  variant="text-primary"
                  className="xs:text-sm
                   text-sm font-bold text-primary hover:underline pl-2"
                  onClick={() => router.push(commonRoutes.buyer.signup)}
                  type="button"
                >
                  {intl.formatMessage({ id: 'page.account.createanaccount' })}
                </Button>
              </div>
            </div>

            <div className="mb-2 lg:mb-2.5 mt-6  lg:mt-5   xs:mt-2 sm:mt-2 md:mt-2">
              <div className="lg:flex items-center justify-center hidden">
                <p className="text-sm text-black-500 dark:text-black-400">
                  {intl.formatMessage({ id: 'getstarted.noAccount' })}
                </p>
                <Button
                  variant="text-primary"
                  className="text-sm font-bold text-primary hover:underline pl-2"
                  onClick={() => router.push(commonRoutes.buyer.signup)}
                  type="button"
                >
                  {intl.formatMessage({ id: 'page.account.createanaccount' })}
                </Button>
              </div>
            </div>
            <div className=" text-center text-sm font-bold text-primary">
              <Link href="/user/reset-password" legacyBehavior>
                {intl.formatMessage({ id: 'getstarted.forgotpassword' })}
              </Link>
            </div>
            <div className="mt-auto flex flex-row items-center">
              <div className="lg:border-t lg:dark:border-t-black-dark w-full"></div>
              {isDesktop && (
                //hide on mobile
                (<div className="px-5 text-md">OR</div>)
              )}
              <div className="lg:border-t lg:dark:border-t-black-dark w-full"></div>
            </div>
            {!isDesktop && <div className="w-[300px] border border-[#635E7D] my-5 mx-auto"></div>}
            <div className="mt-auto lg-mt-2">
              {/* <BuyerSocialSignin /> */}
              <BuyerSocialSigninV2 />
            </div>
          </div>
        </div>
      </div>
      <TermsModal />
    </FormWrapper>)
  );
};
