import { useArrayInfo, useAuthUser, useDashboardModal } from '@hooks';
import BankIcon from '@icons/bank.svg';
import { AxiosError } from 'axios';
import { Button } from '@components/button';
import Script from 'next/script';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ApiService } from '@services/Api';
import { io } from 'socket.io-client';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'array-credit-alerts': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          appKey: string;
          userToken: string;
          sandbox: string;
          bureau: 'efx' | 'exp' | 'tui' | 'all';
        },
        HTMLElement
      >;
    }
  }
}

export const ArrayCreditAlerts: React.FC<{ closeArrayAlerts: Function }> = ({
  closeArrayAlerts,
}) => {
  const { appKey } = useArrayInfo();

  const queryBoy = useQuery<
    {
      userId: string;
      userToken: string;
    },
    {
      data: AxiosError;
    }
  >(
    ['user-token'],
    async () => {
      const resp = await ApiService.getBuyerCreditReportUserToken();
      return resp.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  );

  const { data: arrayUser, isError, error, status } = queryBoy;
  const { setCurrentModal } = useDashboardModal();

  return (
    <>
      <Script src={`https://embed.array.io/cms/array-web-component.js?appKey=${appKey}`} />
      <Script src={`https://embed.array.io/cms/array-credit-alerts.js?appKey=${appKey}`} />
      {isError || !!error?.data || status === 'error' ? (
        <div className="flex flex-col items-center mt-5">
          <>
            <div>You haven’t connected a credit report.</div>
            <BankIcon className="text-[#D0CAE9] text-center my-12" width={33} />
            <div className="mb-6">
              <Button
                variant="primary"
                onClick={() => {
                  closeArrayAlerts();
                  setCurrentModal('createCreditReportUser');
                }}
              >
                Add Credit
              </Button>
            </div>
          </>
        </div>
      ) : (
        <>
          {arrayUser?.userToken && (
            <div>
              <array-credit-alerts
                bureau="efx"
                appKey={appKey}
                userToken={arrayUser.userToken}
                sandbox={process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'false' : 'true'}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
