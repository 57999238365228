import { useCalculateScore } from '@hooks';
import { ErrorState } from '@components/error-state';
import Image from "next/image";

export const AnalyzingData = () => {
  const { error } = useCalculateScore();
  return error ? (
    <ErrorState />
  ) : (
    <div className="text-center flex flex-col justify-center h-full flex-grow my-auto">
      <div>
        <Image
          alt=""
          width={320}
          height={261}
          src="/images/analyzing.png"
          style={{
            maxWidth: "320px",
            height: "261px"
          }} />
      </div>
      <h1 className="font-slab font-semibold text-xl mb-4 mt-10">Analyzing your data...</h1>
      <p className="text-[15px] text-gray-900 max-w-[268px] mx-auto">
        Hold on while we build your profile and select the ideal recommendations.
      </p>
    </div>
  );
};
