import { useAuthUser } from '@hooks';
import { getBase64Src } from '@utils';
import clsx from 'clsx';
import Image from "next/image";
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

export type UserLogoProps = {
  firstName?: string;
  lastName?: string;
  className?: string;
  children?: ReactNode;
};

const styles = {
  base: 'rounded-full w-[40px] h-[40px] flex items-center justify-center text-sm bg-primary-100 font-bold text-primary-500 flex-shrink-0',
};

export const UserLogo = ({ firstName, lastName, className, children }: UserLogoProps) => {
  const { image } = useAuthUser();
  const methods = useFormContext();
  const { profile } = useAuthUser();
  return (
    <div className={clsx(styles.base, className)}>
      {methods?.getValues?.('avatar') || profile?.avatar ? (
        <div className="rounded-full overflow-hidden">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className="object-cover object-center max-w-full "
            alt={`${image} picture`}
            src={methods?.getValues?.('avatar') || profile.avatar}
          />
        </div>
      ) : (
        <>
          {firstName ? (
            <p className="uppercase">
              {firstName[0]}
              {lastName[0]}
            </p>
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
};
