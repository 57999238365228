import { Button, CustomInput, ErrorMessage, ErrorState, FormWrapper, Steps } from '@components';
import { BuyerState, useBuyerSignupInfo } from '@hooks';
import { commonRoutes } from '@utils';
import { AxiosRequestConfig } from 'axios';
import { BuyerSocialSigninV2 } from '@components/social-logins-v2';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { ApiService, Authentication, EAuthenticationType, EUserType } from '@services/Api';

export const Step1 = ({
  onNextStep,
  skipToThirdStep,
}: {
  onNextStep?: Function;
  skipToThirdStep?: Function;
}) => {
  const { setBuyerInfo, email } = useBuyerSignupInfo();

  const router = useRouter();
  const query = router?.query;
  const partnerID = (query?.pc as string) || '';
  const lenderID = (query?.lc as string) || '';
  const associateId = (query?.associateId as string) || '';
  const buyerEmail = (query?.email as string) || '';

  const [associateIdError, setAssociateIdError] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    if (partnerID || lenderID || associateId) {
      if (lenderID)
        setBuyerInfo({
          partnerCode: null,
          lenderCode: lenderID,
          associateId: associateId || null,
        });
      else if (partnerID)
        setBuyerInfo({
          partnerCode: partnerID,
          lenderCode: null,
          associateId: associateId || null,
        });
      else
        setBuyerInfo({
          partnerCode: null,
          lenderCode: null,
          associateId: associateId || null,
        });
      ApiService.signUpVerifyPartnerCode({
        partnerCode: partnerID,
        lenderCode: lenderID,
        associateId,
      })
        .then((data) => {
          const isPartnerCodeVerified = data?.data;
          if (associateId && !isPartnerCodeVerified.associateIdStatus) {
            setAssociateIdError(true);
            return;
          }
          if (
            isPartnerCodeVerified &&
            (isPartnerCodeVerified?.partnerCodeStatus || isPartnerCodeVerified.associateIdStatus)
          ) {
            setBuyerInfo({
              partnerCode: partnerID,
              lenderCode: lenderID,
              accessCode: [{ partnerCode: partnerID, lenderCode: lenderID, associateId }],
              partnerCodeStatus: isPartnerCodeVerified?.partnerCodeStatus,
              associateIdStatus: isPartnerCodeVerified.associateIdStatus,
            });
          } else {
            setBuyerInfo({
              partnerCode: '',
              lenderCode: '',
              accessCode: [],
              partnerCodeStatus: isPartnerCodeVerified?.partnerCodeStatus,
              associateIdStatus: isPartnerCodeVerified.associateIdStatus,
            });
          }
        })
        .catch((error) => {
          if (error?.data?.error === 'user_already_exists') {
            throw new Error('Email already exists, please sign in');
          }
          throw (
            error?.data?.message ||
            'We encountered an error on our side. Please try again in a few minutes.'
          );
        });
    }
  }, [partnerID, lenderID, setBuyerInfo, associateId, buyerEmail]);
  useEffect(() => {
    setBuyerInfo({
      email: buyerEmail,
    });
  }, [buyerEmail, setBuyerInfo]);

  const methods = useForm<Pick<BuyerState, 'email'>>({
    defaultValues: { email },
  });
  const referredBy = router.isReady && (router.query?.referredBy as string);

  const startAuthenticationByEmail = useMutation<
    { authenticationId: string; type: 'signin' | 'signup'; email: string },
    AxiosRequestConfig,
    { email: string }
  >(
    async ({ email }: { email: string }) => {
      const resp = await ApiService.startAuthenticationByEmail({
        email,
        userType: EUserType.buyer,
        type: EAuthenticationType.signup,
        referredBy,
      }).catch((error) => {
        if (error?.data?.error === 'user_already_exists') {
          throw new Error('Email already exists, please sign in');
        }
        throw (
          error?.data?.message ||
          'We encountered an error on our side. Please try again in a few minutes.'
        );
      });

      return {
        email,
        ...resp.data,
      };
    },
    {
      onSuccess: (data) => {
        setBuyerInfo({
          email: data.email,
          authenticationId: data.authenticationId,
        });
        onNextStep && onNextStep();
      },
    },
  );

  const handleBackClick = () => {
    router.push(commonRoutes.buyer.getStarted);
  };
  const intl = useIntl();

  const submitErrorByEmail = startAuthenticationByEmail.error as Error;

  // if the associate link is wrong then the buyer is shown with this error state
  if (associateIdError) return <ErrorState type="wrong_associate_id" />;
  return (
    (<FormWrapper
        onBackClick={handleBackClick}
        onSubmit={methods.handleSubmit((values) => {
          startAuthenticationByEmail.mutate(values as Authentication);
        })}
        methods={methods}
        isLoading={startAuthenticationByEmail.isLoading}
        // containerClassName="lg:px-0 mb-0 lg:-mt-[31px]"
      >
      <div className="flex h-full bg-white p-5 ">
        <div className="flex flex-col w-full lg:justify-center xs:px-0">
          <Steps.Title className="mb-2.5 text-center">Create an Account</Steps.Title>
          <p className="text-[15px] text-center text-gray-500 dark:text-gray-400  xs:mb-5 sm:mb-3 md:mb-3 lg:mb-5">
            {intl.formatMessage({ id: 'page.account.mobilesignin' })}
          </p>
          <CustomInput.Email
            showLabel={true}
            placeholder="your.email@example.com"
            label="Email Address"
          />

          {startAuthenticationByEmail.isError && methods.formState.errors?.email == null && (
            <ErrorMessage
              error={startAuthenticationByEmail.error?.data?.message || submitErrorByEmail.message}
            />
          )}
          {/* <Steps.ButtonContainer> */}
          {/* <Steps.BackButton
          className="hidden lg:flex"
          onClick={() => {
            handleBackClick();
          }}
        />*/}

          <Steps.ContinueButton className=" mt-7" />
          <div className="mb-2 lg:mb-2.5 mt-6 lg:mt-5">
            <div className="flex flex-row text-center items-center gap-1 mt-6 justify-center">
              <p className="text-sm text-black-500 dark:text-black-400">
                {intl.formatMessage({ id: 'getstarted.hasAccount' })}
              </p>
              <Button
                variant="text-primary"
                className="xs:text-xs text-sm font-bold text-primary hover:underline pl-2"
                onClick={() => router.push(commonRoutes.buyer.signin)}
                type="button"
              >
                {intl.formatMessage({ id: 'page.account.signin' })}
              </Button>
            </div>
          </div>
          <div className=" text-center text-sm font-bold text-primary">
            <Link href="/user/reset-password" legacyBehavior>
              {intl.formatMessage({ id: 'getstarted.forgotpassword' })}
            </Link>
          </div>
          {/* </Steps.ButtonContainer> */}
          <div className=" mt-auto lg:pt-4 flex flex-row items-center">
            <div className="lg:border-t lg:dark:border-t-black-dark w-full"></div>
            {!isDesktop ? (
              <hr className="mt-2 w-[50%] items-center" />
            ) : (
              //hide on mobile
              (<div className="px-5 text-md">OR</div>)
            )}
            <div className="lg:border-t lg:dark:border-t-black-dark w-full"></div>
          </div>
          <div className="mt-auto lg:pt-4 lg:mt-4">
            {/* <BuyerSocialSignin skipToThirdStep={skipToThirdStep} /> */}
            <BuyerSocialSigninV2 skipToThirdStep={skipToThirdStep} />
          </div>
        </div>
      </div>
    </FormWrapper>)
  );
};
