// Utils
export type ListRequestResponse<T> = {
  items: T[];
  total: number;
};

export type ListItemRecord = {
  value: string;
  label: string;
};

// Models

export enum ChildrenUnder18CountOptions {
  zeroTwo = '0-2',
  threeFive = '3-5',
  sixTen = '6-10',
  moreThanTen = 'more_than_10',
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  userType: EUserType;
  email: string;
  password: string;
  isEmailVerified: boolean;
  agreedToTerms: boolean;
  phone: string | null;
  isPhoneVerified: boolean;
  isOnboarded: boolean;
  buyerId: string | null;
  createdAt: string;
  updatedAt: string;
  acceptPhoneConsent: boolean;
  declinePhoneConsent: boolean;
  userJourney?: UserJourney;
  partnerName?: string;
  lenderName?: string;
};

export type GoogleBuyer = {
  id: string;
  accessCode: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  avatar: string;

  approvalStatus: 'approved';
  housePriceGoal: number;
  expectedPurchaseMonthsPeriod: number;
  wishedLocations: WishedLocation[];

  isInitialDataFetched: boolean;
  isFetchingVerifiedData: boolean;
  lastVerificationRunAt: string | null;
  onboardingProfileStatus: EOnboardingStepStatus;
  onboardingTransactionAccountStatus: EOnboardingStepStatus;
  onboardingCreditReportStatus: EOnboardingStepStatus;
  onboardingIncomeAccountStatus: EOnboardingStepStatus;

  dateOfBirth: string | null;
  gender: EGender | null;
  race: string | null;
  hispanicOrigin: boolean | null;
  children: boolean | null;
  childrenUnder18Count: ChildrenUnder18CountOptions | null;
  ssn: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  apartment: string | null;
  zipCode: string | null;
  occupation: string | null;
  militaryStatus: EMilitaryStatus | null;
  reasonForPurchase: EReasonForPurchase | null;

  // Is only set to true after all steps are completed
  isOnboarded: boolean;
  allowedEmails?: BuyerAllowedEmails[];
  allowedSms?: BuyerAllowedSms[];
  preferredContactMethod?: { email: boolean; sms: boolean };
  houseRent?: MonthlyRent;

  coBorrower?: boolean;
  coBorrowersFirstName?: string;
  coBorrowersLastName?: string;
  coBorrowersEmail?: string;

  coBorrowerInvited?: boolean;
  coBorrowerInvite?: ICoBorrowerInvite;
  isWelcomeFlashCardsShown?: boolean;
  isRewardsWelcomeShown?: boolean;

  onboardingPathTaken?: EOnboardingPathTaken;
};

// Idea is to have this as an enum
type BuyerAllowedEmails = 'recommendations' | 'account_setup' | 'journey_notifications';

// Idea is to have this as an enum
type BuyerAllowedSms = 'recommendations';
type MonthlyRent =
  | 'one_thousand'
  | 'thousand_and_one_to_two_thousand'
  | 'two_thousand_and_one_to_three_thousand'
  | 'three_thousand_and_one_to_four_thousand'
  | 'above_four_thousand'
  | 'na';

export type Buyer = {
  id: string;
  accessCode: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  avatar: string;
  approvalStatus: EApprovalStatus;
  housePriceGoal: number;
  expectedPurchaseMonthsPeriod: number;
  wishedLocations: WishedLocation[];

  isInitialDataFetched: boolean;
  isFetchingVerifiedData: boolean;
  lastVerificationRunAt: string | null;
  onboardingProfileStatus: EOnboardingStepStatus;
  onboardingTransactionAccountStatus: EOnboardingStepStatus;
  onboardingCreditReportStatus: EOnboardingStepStatus;
  onboardingIncomeAccountStatus: EOnboardingStepStatus;

  dateOfBirth: string | null;
  gender: EGender | null;
  race: string | null;
  hispanicOrigin: boolean | null;
  children: boolean | null;
  childrenUnder18Count: ChildrenUnder18CountOptions | null;
  ssn: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  apartment: string | null;
  zipCode: string | null;
  occupation: string | null;
  militaryStatus: EMilitaryStatus | null;
  reasonForPurchase: EReasonForPurchase | null;
  acceptPhoneConsent: boolean;
  declinePhoneConseent: boolean;

  // Is only set to true after all steps are completed
  isOnboarded: boolean;
  allowedEmails?: BuyerAllowedEmails[];
  allowedSms?: BuyerAllowedSms[];
  preferredContactMethod?: { email: boolean; sms: boolean };
  houseRent?: MonthlyRent;

  coBorrower?: boolean;
  coBorrowersFirstName?: string;
  coBorrowersLastName?: string;
  coBorrowersEmail?: string;

  coBorrowerInvited?: boolean;
  coBorrowerInvite?: ICoBorrowerInvite;
  isWelcomeFlashCardsShown?: boolean;
  isRewardsWelcomeShown?: boolean;

  onboardingPathTaken?: EOnboardingPathTaken;
};

export type WishedLocation = {
  country: string;
  state: string;
  city?: string;
};

type ProfileUserFields = Pick<
  User,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'userType'
  | 'userJourney'
  | 'lenderName'
  | 'partnerName'
>;

type GoogleBuyerProfileFields = Pick<
  GoogleBuyer,
  | 'id'
  | 'userId'
  | 'approvalStatus'
  | 'isOnboarded'
  | 'onboardingProfileStatus'
  | 'onboardingTransactionAccountStatus'
  | 'onboardingCreditReportStatus'
  | 'onboardingIncomeAccountStatus'
  | 'isInitialDataFetched'
  | 'isFetchingVerifiedData'
  | 'lastVerificationRunAt'
  | 'housePriceGoal'
  | 'expectedPurchaseMonthsPeriod'
  | 'wishedLocations'
  | 'dateOfBirth'
  | 'gender'
  | 'race'
  | 'hispanicOrigin'
  | 'children'
  | 'childrenUnder18Count'
  | 'street'
  | 'apartment'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'occupation'
  | 'militaryStatus'
  | 'reasonForPurchase'
  | 'createdAt'
  | 'updatedAt'
  | 'accessCode'
  | 'allowedEmails'
  | 'allowedSms'
  | 'avatar'
  | 'coBorrower'
  | 'coBorrowersFirstName'
  | 'coBorrowersLastName'
  | 'coBorrowersEmail'
  | 'coBorrowerInvited'
  | 'coBorrowerInvite'
  | 'isWelcomeFlashCardsShown'
  | 'isRewardsWelcomeShown'
  | 'houseRent'
  | 'preferredContactMethod'
  | 'onboardingPathTaken'
>;

type BuyerProfileFields = Pick<
  Buyer,
  | 'id'
  | 'userId'
  | 'approvalStatus'
  | 'isOnboarded'
  | 'onboardingProfileStatus'
  | 'onboardingTransactionAccountStatus'
  | 'onboardingCreditReportStatus'
  | 'onboardingIncomeAccountStatus'
  | 'isInitialDataFetched'
  | 'isFetchingVerifiedData'
  | 'lastVerificationRunAt'
  | 'housePriceGoal'
  | 'expectedPurchaseMonthsPeriod'
  | 'wishedLocations'
  | 'dateOfBirth'
  | 'gender'
  | 'race'
  | 'hispanicOrigin'
  | 'children'
  | 'childrenUnder18Count'
  | 'street'
  | 'city'
  | 'state'
  | 'apartment'
  | 'zipCode'
  | 'occupation'
  | 'militaryStatus'
  | 'reasonForPurchase'
  | 'createdAt'
  | 'updatedAt'
  | 'accessCode'
  | 'allowedEmails'
  | 'allowedSms'
  | 'avatar'
  | 'coBorrower'
  | 'coBorrowersFirstName'
  | 'coBorrowersLastName'
  | 'coBorrowersEmail'
  | 'coBorrowerInvited'
  | 'coBorrowerInvite'
  | 'isWelcomeFlashCardsShown'
  | 'isRewardsWelcomeShown'
  | 'houseRent'
  | 'preferredContactMethod'
  | 'onboardingPathTaken'
>;

export type GoogleBuyerProfile = ProfileUserFields & GoogleBuyerProfileFields;

export type BuyerProfile = ProfileUserFields & BuyerProfileFields;

export type CoBorrowerProfile = {
  email: string;
  firstName: string;
  lastName: string;
};

export type BuyerOnboardingStatus = {
  onboardingProfileStatus: EOnboardingStepStatus;
  onboardingTransactionAccountStatus: EOnboardingStepStatus;
  onboardingCreditReportStatus: EOnboardingStepStatus;
  onboardingIncomeAccountStatus: EOnboardingStepStatus;
};

export enum EUserType {
  buyer = 'buyer',
  partner = 'partner',
}

export enum EAuthenticationType {
  signin = 'signin',
  signup = 'signup',
}

export interface Authentication {
  id: string;
  type: 'email' | 'phone';
  phone: string | null;
  email: string | null;
  password: string | null;
  code: string;
  isVerified: boolean;
  referredByUserId: string | null;
  partialSignupForm: AuthenticationPartialSignupForm;
  createdAt: string;
  updatedAt: string;
}

export interface AuthenticationPartialSignupForm {
  values: PartialSignUpFormValues;
}

type UserFields = Pick<User, 'firstName' | 'lastName' | 'email'>;
type PartialSignUpFormValues = Partial<
  UserFields & Omit<Buyer, 'authenticationId' | 'id' | 'createdAt' | 'updatedAt'>
>;

export type AccountType = {
  account_id: string;
  balances: {
    available: number;
    current: number;
    limit: number;
    iso_currency_code: string;
    unofficial_currency_code: string;
  };
  mask: string;
  name: string;
  official_name: string;
  subtype: string;
  type: string;
  logo?: string;
};

export type UserJourney = 'designer' | 'builder';

export interface NextGrade {
  grade: string;
  pointRangeMin: number;
  pointRangeMax: number;
  status: string;
}

export interface TotalScore {
  value: number;
  grade: string;
  pointRangeMin: number;
  pointRangeMax: number;
  status: string;
  nextGrade: NextGrade;
  pointRangeMinTotal: number;
  pointRangeMaxTotal: number;
}

export enum EOnboardingStepStatus {
  skipped = 'skipped',
  pending = 'pending',
  completed = 'completed',
}

export enum EOnboardingPathTaken {
  null = 'null',
  quick_setup = 'quick_setup',
  complete_setup = 'complete_setup',
  skipped_to_dashboard = 'skipped_to_dashboard',
}

export enum EApprovalStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export enum EGender {
  male = 'male',
  female = 'female',
  transgender = 'transgender',
  non_binary = 'non_binary',
  prefer_not_to_say = 'prefer_not_to_say',
}

export enum EEthnicity {
  hispanic_origin,
}

export enum EMilitaryStatus {
  active = 'active',
  veteran = 'veteran',
  notApplicable = 'notApplicable',
}

export enum EReasonForPurchase {
  build_wealth = 'build_wealth',
  better_schools = 'better_schools',
  safer_neighborhood = 'safer_neighborhood',
  stop_paying_rent = 'stop_paying_rent',
  tax_benefits = 'tax_benefits',
  place_to_raise_family = 'place_to_raise_family',
  other = 'other',
}

export enum EMonthlyRent {
  one_to_one_thousand = 'one_to_one_thousand',
  one_thousand_and_one_to_two_thousand = 'one_thousand_and_one_to_two_thousand',
  two_thousand_and_one_to_three_thousand = 'two_thousand_and_one_to_three_thousand',
  three_thousand_and_one_to_four_thousand = 'three_thousand_and_one_to_four_thousand',
  above_four_thousand = 'above_four_thousand',
  na = 'na',
}

export interface ScoreResult {
  value: number;
  grade: string;
  pointRangeMin: number;
  pointRangeMax: number;
  status: string;
  nextGrade: NextGrade;
  updatedAt: string;
}

export interface Grades {
  totalScore: TotalScore;
  creditScore: ScoreResult;
  debtToIncome: ScoreResult;
  downpaymentPercentageSaved: ScoreResult;
  grossIncome: ScoreResult;
}

export interface ConfigItem {
  min: number;
  max: number;
  points: number;
  grade: string;
  status: string;
}

export interface Config {
  creditScore: ConfigItem[];
  debtToIncome: ConfigItem[];
  downpaymentPercentageSaved: ConfigItem[];
  grossIncome: ConfigItem[];
  totalScore: ConfigItem[];
}

export interface CalculateScoreResponse {
  id: string;
  grades: Grades;
  config: Config;
  createdAt: string;
  updatedAt: string;
}

export interface ManualData<Category = string, Frequency = string> {
  category: Category;
  frequency?: Frequency;
  isVerified: boolean;
  amount: number;
  id: string;
}

export type BuyerIncome = {
  id: string;
  grossPayAmount: number;
  netPayAmount: number;
  currencyCode: string;
  category: EBuyerIncomeCategory;
  frequency: EBuyerIncomeFrequency;
  startDate: string; // ISO 8601
  endDate: string; // ISO 8601
  isRecurring: boolean;
  paymentDate: string;
  employerName?: string;
  employerAddressStreet?: string;
  employerAddressCity?: string;
  employerAddressRegion?: string;
  employerAdressCountry?: string;
  isVerified: boolean;
  verifiedPlaidIncomeDocumentId?: string;
  verifiedPlaidItemId?: string;
  buyerId: string;
  createdAt: string; // ISO 8601
  updatedAt: string; // ISO 8601
  verifiedPlaidAccount: VerifiedPlaidAccount;
};

export type BuyerDebt = {
  id: string;
  name: string | null;
  amount: number;
  currencyCode: string;
  category: EBuyerDebtCategory;
  paymentChannel: EPlaidTransactionPaymentChannel;
  startDate: string; // ISO 8601
  endDate: string; // ISO 8601
  isRecurring: boolean;
  isVerified: boolean;
  buyerId: string;
  createdAt: string; // ISO 8601
  updatedAt: string; // ISO 8601
  verifiedPlaidAccount: VerifiedPlaidAccount;
};

export interface PlaidItem {
  itemId: string;
  institutionName: string;
  institutionId: string;
  institutionLogo?: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface VerifiedPlaidAccount {
  accountId: string;
  accountBalanceAvailable?: any;
  accountBalanceCurrent: number;
  accountBalanceLimit?: any;
  accountBalanceIsoCode: string;
  accountBalanceLastUpdated?: any;
  name: string;
  mask: string;
  type: string;
  subType: string;
  plaidItemId: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  plaidItem: PlaidItem;
}

export type BuyerSaving = {
  id: string;
  name?: string;
  amount: number;
  currencyCode: string;
  category: EBuyerSavingsCategory;
  date: string; // ISO 8601
  isRecurring: boolean;
  isVerified: boolean;
  createdAt: string; // ISO 8601
  updatedAt: string; // ISO 8601
  verifiedPlaidAccountId: string;
  verifiedPlaidItemId: string;
  buyerId: string;
  verifiedPlaidAccount: VerifiedPlaidAccount;
};

export enum EBuyerIncomeCategory {
  SALARY = 'SALARY',
  UNEMPLOYMENT = 'UNEMPLOYMENT',
  CASH = 'CASH',
  GIG_ECONOMY = 'GIG_ECONOMY',
  RENTAL = 'RENTAL',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  MILITARY = 'MILITARY',
  RETIREMENT = 'RETIREMENT',
  LONG_TERM_DISABILITY = 'LONG_TERM_DISABILITY',
  BANK_INTEREST = 'BANK_INTEREST',
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  TRANSFER_FROM_APPLICATION = 'TRANSFER_FROM_APPLICATION',
  TAX_REFUND = 'TAX_REFUND',
  OTHER = 'OTHER',
}

export enum EBuyerSavingsCategory {
  DEPOSITORY_SAVINGS = 'DEPOSITORY_SAVINGS',
  DEPOSITORY_CD = 'DEPOSITORY_CD',
  DEPOSITORY_MONEY_MARKET = 'DEPOSITORY_MONEY_MARKET',
  INVESTMENT_IRA = 'INVESTMENT_IRA',
  INVESTMENT_401K = 'INVESTMENT_401K',
  CASH = 'CASH',
  OTHER = 'OTHER',
}

export enum EBuyerIncomeFrequency {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  MONTHLY = 'MONTHLY',
}

export enum EBuyerDebtCategory {
  RENT = 'RENT',
  MORTGAGE = 'MORTGAGE',
  HOME_EQUITY = 'HOME_EQUITY',
  LINE_OF_CREDIT = 'LINE_OF_CREDIT',
  CREDIT_CARD = 'CREDIT_CARD',
  AUTO_LOAN = 'AUTO_LOAN',
  STUDENT_LOAN = 'STUDENT_LOAN',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
}

export enum EPlaidTransactionPaymentChannel {
  online = 'online',
  in_store = 'in_store',
  other = 'other',
}

export enum EUserFeedbackReason {
  bug = 'bug',
  suggestion = 'suggestion',
  question = 'question',
  comment = 'comment',
}

export enum ERecommendationGap {
  credit_score = 'credit_score',
  dti = 'dti',
  down_payment = 'down_payment',
}
export interface BuyerRecommendation {
  id: string;
  status: EBuyerRecommendationStatus;
  category: EBuyerRecommendationCategory;
  type: EBuyerRecommendationType;
  title: string;
  description: string;
  // If we have a link to a somewhere else, we can put it here
  linkUrl?: string;
  linkLabel?: string;
  // For now, we only have a ruleId, but later, we'll be doing a relation to a rule
  ruleId: string;
  // Data returned by the rule, when the recommendation was created
  // - Can be used to generate the title, or description, or both
  // - Can be used to generate the link
  metadata: any;
  createdAt: Date;
  updatedAt: Date;
  gap: ERecommendationGap;
  subGap: string;
  benefit: string;
}

export enum EBuyerRecommendationCategory {
  accounts = 'accounts',
  debt_to_income = 'debt_to_income',
  credit = 'credit',
  income = 'income',
  savings = 'savings',
  other = 'other',
}

export enum EBuyerRecommendationType {
  action = 'action',
  alert = 'alert',
}

export enum EBuyerRecommendationStatus {
  active = 'active',
  inProgress = 'inProgress',
  completed = 'completed',
  deferred = 'deferred',
  dismissed = 'dismissed',
  pinned = 'pinned',
  history = 'history',
  not_active = 'not_active',
}
export interface ICoBorrowerInvite {
  id: string;
  inviterId: string;
  coBorrowersFirstName: string;
  coBorrowersLastName: string;
  coBorrowersEmail: string;
  accepted: boolean;
  declined: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IRewards {
  id: string;
  description: string;
  actionSlug: string;
  imageUrl: string;
  pointsRewarded: number;
  name: string;
  completedReward: boolean;
}

export interface ICreateMessage {
  adminId: string;
  messageText: string;
}

export interface IFilter {
  id: string;
  logic: string;
  column: string;
  columnLabel?: string;
  operator: string;
  operatorLabel?: string;
  value: string | string[];
  isActive?: boolean;
}

export interface IUserFilter {
  id: string;
  filterName: string;
  isActive: boolean;
  filterLogic: IFilter[];
}
