import { Button } from '@components/button';
import { gradeInfo, Progress, ProgressProps } from '@components/progress';
import { ReactNode } from 'react';
import StickerBackgroundIcon from '@icons/sticker.svg';
import Image from "next/image";

import clsx from 'clsx';
import Link from 'next/link';

export const DashboardItem = ({
  cardColorClassName,
  title,
  description,
  actionTitle,
  Icon,
  children,
  canClickAction,
  onCardClick,
  onActionClick,
  qtySuggestions,
  routeButtonText,
  routeIcon,
  href,
  time,
  iconColor,
  ...progressProps
}: {
  cardColorClassName: string;
  title: string;
  description: string;
  actionTitle: string;
  Icon: (props) => JSX.Element;
  children?: ReactNode;
  canClickAction: boolean;
  onCardClick: Function;
  onActionClick: Function;
  qtySuggestions?: number;
  routeButtonText?: string;
  routeIcon?: ReactNode;
  href?: string;
  time?: string;
  iconColor?: string;
} & ProgressProps) => {
  return (
    <button
      className={clsx(
        'p-[20px] rounded-2xl min-h-[200px] mb-[20px] flex flex-col',
        cardColorClassName,
        {
          'cursor-default': canClickAction,
        },
      )}
      onClick={(e) => !canClickAction && onCardClick(e)}
    >
      <div className="mb-[24px]  flex items-center gap-4 w-full">
        <div className="w-full">
          <div className="flex justify-between  w-full items-center">
            <div className=" flex items-center gap-2">
              <Icon style={{ color: iconColor }} />
              <div className="font-semibold cursor-pointer font-plusJakarta text-[12px] leading-4">{title}</div>
            </div>
            <div className="bg-white flex justify-end py-1 px-2 rounded-full ">
              <span className="text-[10px] leading-[15px] font-normal">
                {time ? time : '2 min'}
              </span>
            </div>
          </div>
          {progressProps?.grade && qtySuggestions > 0 && (
            <div
              style={{ backgroundColor: gradeInfo[progressProps.grade]?.color }}
              className="rounded font-normal text-white h-[35px] w-[90px] flex justify-center items-center my-auto text-[15px] text-center"
            >
              {qtySuggestions} Actions
            </div>
          )}
        </div>
      </div>

      {/* {routeButtonText && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="border bg-green-300 rounded-lg py-2 mb-3 px-3  w-full"
        >
          <Link href={href}>
            <a className="flex flex-row justify-between items-center text-center">
              <h4 className="text-[12px] text-white text-left font-plusJakarta">{routeButtonText}</h4>
              {routeIcon && routeIcon}
            </a>
          </Link>
        </button>
      )} */}
      <div className="font-normal text-left text-[12px] leading-4 font-plusJakarta mb-2">
        {description}
      </div>
      {canClickAction ? (
        <h1
          className="mt-auto font-bold font-plusJakarta text-[12px] cursor-pointer leading-4 text-[#0AAA82]"
          onClick={(e) => {
            e.stopPropagation();
            onActionClick();
          }}
        >
          {actionTitle}
        </h1>
      ) : (
        <div className="w-full">
          <Progress {...progressProps} />
        </div>
      )}
      {children}
    </button>
  );
};
