import { useAuthUser } from '@hooks';
import { Button } from '@components/button';
import { CustomInput } from '@components/custom-input';
import { UserLogo } from '@components/layout/header/user-logo';
import states from '@data/states.json';
import { SearchLocation } from '@components/buyer-signup-form/step7';
import { ErrorMessage } from '@components/error-message';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { DeleteAccountModal } from '../delete-account-modal';
import { extractNumbers } from '@utils';
import { UploadImage } from '@components/modal/upload-picture-modal';
import { Address } from '@components/address';
import { InviteCoBorrower } from '@components/custom-input/co-borrower';
import CoBorrowerInviteDisplay from '@components/co-borrower/invite-display';
import CoBorrowerDetails from '@components/co-borrower/co-borrower-details';
import { DisAssociateCoBorrower } from '@components/co-borrower';
import { EMonthlyRent } from '@services/Api';

const EmailSettingsOptions = [
  {
    label: 'Recommendations',
    value: 'recommendations',
  },
  {
    label: 'Account Setup',
    value: 'account_setup',
  },
  {
    label: 'Journey Notifications',
    value: 'journey_notifications',
  },
  {
    label: 'Marketing',
    value: 'marketing',
    disabled: true,
  },
];

const SmsSettingsOptions = [
  {
    label: 'Recommendations',
    value: 'recommendations',
  },
  {
    label: 'Marketing',
    value: 'marketing',
    disabled: true,
  },
];

export const SettingsContent = () => {
  const methods = useFormContext();
  const user = useAuthUser();
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  return (
    <div className="bg-gray-50">
      <div className="bg-white p-6 lg:pb-10 lg:px-8 border-b border-b-gray-200 lg:border-0">
        <h3 className="lg:text-2xl text-xl font-medium">Profile</h3>
        <div className="flex gap-16 items-center mt-6">
          <UserLogo
            className="!w-[64px] !h-[64px] lg:!w-[80px] lg:!h-[80px] text-2xl"
            firstName={user.firstName}
            lastName={user.lastName}
          />
          <UploadImage />
        </div>
        <div className="flex flex-col gap-6 mt-6">
          {methods.getValues()?.accessCode && (
            <CustomInput.Input
              icon={null}
              labelClassName="!text-gray-900"
              name="accessCode"
              label="Access code"
              disabled
            />
          )}
          <div className="grid grid-cols-2 gap-4">
            <CustomInput.Name disabled />
            <CustomInput.Name name="lastName" disabled />
          </div>

          <CustomInput.Gender />
          <CustomInput.Race />
          <CustomInput.HispanicOrigin />
          <CustomInput.ChildrenSelect />
          <CustomInput.BirthDate />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 items-center">
            <CustomInput.Occupation required={false} />
            <CustomInput.MilitaryStatus showRequired={false} />
          </div>
          {/* make this a component */}
          <CustomInput.Select
            name="houseRent"
            options={[
              {
                label: '$1 - $1,000',
                value: EMonthlyRent.one_to_one_thousand,
              },
              {
                label: '$1,001 - $2,000',
                value: EMonthlyRent.one_thousand_and_one_to_two_thousand,
              },
              {
                label: '$2,001 - $3,000',
                value: EMonthlyRent.two_thousand_and_one_to_three_thousand,
              },
              {
                label: '$3,001 - $4,000',
                value: EMonthlyRent.three_thousand_and_one_to_four_thousand,
              },
              {
                label: 'Above $4,000',
                value: EMonthlyRent.above_four_thousand,
              },
              {
                label: 'None of the above',
                value: EMonthlyRent.na,
              },
            ]}
            label="Monthly Rent"
            showRequired={false}
          />
        </div>
      </div>

      <div
        className="mt-2 lg:mt-0 bg-white p-6 lg:pb-10 lg:pt-0 lg:px-8 border-y lg:border-0 border-y-gray-200"
        id="step4"
      >
        <h3 className="lg:text-2xl text-xl font-medium mb-6">Co-Borrower</h3>
        <div className="flex flex-col gap-6">
          {user?.profile?.coBorrower ? (
            <>
              <CoBorrowerDetails />
              <DisAssociateCoBorrower />
            </>
          ) : user?.profile?.coBorrowerInvited ? (
            <CoBorrowerInviteDisplay />
          ) : (
            <InviteCoBorrower />
          )}
        </div>
      </div>

      <div className="mt-2 lg:mt-0 bg-white p-6 lg:pb-10 lg:pt-0 lg:px-8 border-y lg:border-0 border-y-gray-200">
        <h3 className="lg:text-2xl text-xl font-medium mb-6">Contact</h3>
        <div className="flex flex-col gap-6">
          <CustomInput.CountryPhone
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
          <CustomInput.Email icon={null} name="email" label="Email" disabled />
        </div>
      </div>

      <div className="mt-2 lg:mt-0 bg-white p-6 lg:pb-10 lg:pt-0 lg:px-8 border-y lg:border-0 border-y-gray-200">
        <h3 className="lg:text-2xl text-xl font-medium mb-6">Email & Text settings</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <CustomInput.CheckBoxOptions
            nameOnForm="allowedEmails"
            options={EmailSettingsOptions}
            label="Emails: "
          />
          <CustomInput.CheckBoxOptions
            nameOnForm="allowedSms"
            options={SmsSettingsOptions}
            label="Text: "
          />
        </div>
      </div>

      <div className="mt-2 lg:mt-0 bg-white lg:border-0 p-6 lg:pb-10 lg:pt-0 lg:px-8 border-t border-t-gray-200">
        <h3 className="lg:text-2xl text-xl font-medium">Current address</h3>
        <Address />
      </div>

      <div className="bg-white p-6 lg:pb-10 lg:pt-0 lg:px-8 border-t border-t-gray-200 lg:border-0">
        <h3 className="text-xl font-medium">Target address k</h3>
        <div className="flex flex-col gap-6 mt-6">
          <CustomInput.WishedLocation required={false} />
          {methods.formState.errors.wishedLocations && (
            <ErrorMessage
              className="-mt-1"
              error={methods.formState.errors.wishedLocations?.message as string}
            />
          )}
        </div>
      </div>
      <div className="bg-white p-6 lg:pt-0 lg:pb-10 lg:px-8 border-y border-y-gray-200 lg:border-0">
        <CustomInput.ReasonForPurchase showRequired={false} />
      </div>
      <div className="h-[8px] bg-gray-50 lg:hidden"></div>
      <div className="p-6 pb-10 lg:pt-0 lg:px-8 lg:pb-12 bg-white">
        <p className="font-medium text-sm mb-2">Erase all my data</p>
        <Button
          type="button"
          className="lg:w-max"
          onClick={() => {
            setShowDeleteAccount(true);
          }}
          variant="red"
        >
          DELETE ACCOUNT
        </Button>
      </div>
      <DeleteAccountModal
        open={showDeleteAccount}
        onClose={() => {
          setShowDeleteAccount(false);
        }}
      />
    </div>
  );
};

export const SettingsSave = () => {
  const methods = useFormContext();
  const intl = useIntl();
  const isValid = Object.keys(methods.formState.errors).length === 0;
  const copyDirtyFields = { ...methods.formState.dirtyFields };
  const phone = methods.watch('phone');
  const { profile } = useAuthUser();
  if (extractNumbers(phone).trim() === extractNumbers(profile?.phone).trim()) {
    delete copyDirtyFields?.phone;
  }
  const isDirty = Object.keys(copyDirtyFields).length > 0;

  return (
    isDirty &&
    isValid && (
      <div className="rounded flex items-center justify-between bg-black-slate px-4 fixed text-xs text-white py-[10px] min-w-[350px] bottom-[calc(77px+50px)] lg:bottom-[50px] left-1/2 -translate-x-1/2 z-30">
        <p>{intl.formatMessage({ id: 'page.settings.user.unsaved' })}</p>
        <Button
          className="rounded-[4px] bg-primary-500 hover:bg-primary-600 w-max !min-h-[40px]"
          type="submit"
        >
          {intl.formatMessage({ id: 'general.save' })}
        </Button>
      </div>
    )
  );
};
