// context/TourContext.js
import { createContext, useContext, useState } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { useRouter } from 'next/router';
import { useAuthUser } from '@hooks/use-auth';
import { useMutation } from 'react-query';
import { ApiService } from '@services/Api';
import index from 'pages/user/rewards';

const TourContext = createContext({
  startTour: (steps: any[]) => {},
  isTourActive: false,
});

export const RenderContent = ({ title, content }) => {
  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-2xl font-medium text-gray-900 dark:text-white font-plusJakarta">
        {title}
      </h1>
      <p className="text-gray-900 dark:text-white font-plusJakarta">{content}</p>
    </div>
  );
};

export const TourProvider = ({ children }) => {
  const { refetchUserInfo, profile } = useAuthUser();

  const updateProfileMutation = useMutation(
    async () => {
      const { data } = await ApiService.updateBuyer({ isWelcomeFlashCardsShown: true });
      return data;
    },
    {
      onSuccess: async ({ profile }) => {
        await refetchUserInfo();
      },
    },
  );

  const [isTourActive, setIsTourActive] = useState(false);
  const router = useRouter();
  const intro = introJs();
  const startTour = (steps) => {
    setIsTourActive(true);
    intro
      .setOptions({
        steps: steps.map((step, idx) => ({
          ...step,
          intro: step.intro(),
        nextlabel:'Next',
        prevLabel: idx === 0 ? 'ok' : 'Previous',
        })),
        exitOnOverlayClick: false,
        overlayOpacity: 0.8,
        keyboardNavigation: true,
        disableInteraction: true,
        // skipLabel: 'Skip Tour',
        nextLabel: 'Next',
        prevLabel: 'Previous',
        doneLabel: 'Close',
        tooltipClass: 'introjs-tooltip-custom',
        showStepNumbers: true,
      })
      .onbeforechange((targetElement) => {
        if (targetElement.id === 'step2') {
          window.scrollBy(0, 250);
        }

        return true;
      })
      .onexit(() => {
        updateProfileMutation.mutate();

        setIsTourActive(false);
      })
      .start();
  };

  return (
    <TourContext.Provider value={{ startTour, isTourActive }}>{children}</TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
