import { Button } from '@components/button';
import { Modal } from '@components/modal';
import { FullModal } from '@components/modal/full-modal';
import { FeedbackContent } from '@components/settings/settings-feedback';
import { useMutation } from 'react-query';
import Helpingout from '@icons/helping-out.svg';
import { useState } from 'react';
import { Loading } from '@components/loading';
import { ApiService, EUserFeedbackReason } from '@services/Api';

export const FeedbackModal = ({ onClose, open }: { open?: boolean; onClose?: () => void }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const mutation = useMutation(
    async (values: { reason: EUserFeedbackReason; feedback: string }) => {
      await ApiService.sendFeedback(values.reason, values.feedback);
    },
    {
      onSuccess: () => {
        onClose();
        setShowSuccessModal(true);
      },
    },
  );

  const onSubmit = (values) => {
    mutation.mutate(values);
  };
  return (
    <>
      <Loading isLoading={mutation.isLoading} />
      <FullModal
        onClose={() => {
          onClose();
        }}
        open={open}
        title="Give us feedback"
        onClickBackButton={onClose != null ? onClose : undefined}
      >
        <FeedbackContent
          onSubmit={(values) => {
            onSubmit(values);
          }}
        />
      </FullModal>
      <Modal
        open={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
        }}
        wrapperClassName="flex min-h-[100%] items-center justify-center p-4 text-center"
        panelClassName="w-full lg:max-w-[400px] rounded-lg pt-8 px-6 text-center"
      >
        <div className="mb-8 mx-auto w-max">
          <Helpingout className="keep-color" />
        </div>
        <h2 className="text-2xl font-medium mb-3">Thanks for helping out!</h2>
        <p className="text-sm text-gray-600 mb-8">
          We&apos;re always searching for ways to improve My Home Pathway, and your input is an
          important part of that.
        </p>
        <Button
          type="button"
          className="mb-8"
          onClick={() => {
            setShowSuccessModal(false);
          }}
        >
          Go back
        </Button>
      </Modal>
    </>
  );
};
