import { CleanModal } from 'components/modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button } from 'components/button';
import { MouseEventHandler, ReactNode } from 'react';

export const GenericModal = ({
  title,
  content,
  actionTitle,
  onActionClick,
  cancelActionTitle,
  cancelAction,
  actionForm,
  onClose,
}: {
  title: ReactNode | string;
  content: ReactNode;
  actionTitle?: string;
  cancelActionTitle?: string;
  cancelAction?: MouseEventHandler;
  onActionClick?: MouseEventHandler;
  actionForm?: string;
  onClose?: Function;
}) => {
  return (
    <CleanModal
      open
      onClose={() => !!onClose && onClose()}
      wrapperClassName="p-2 md:!items-center "
      panelClassName="w-full w-[450px] rounded-[35px]"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between w-full mb-[0px]">
          <h3 className=" text-[25px] font-bold text-[#1C1A27]">{title}</h3>
          {!!onClose && (
            <button onClick={() => onClose()} type="button">
              <CloseOutlinedIcon />
            </button>
          )}
        </div>
      </div>
      <div className="text-[15px] text-[#635E7D] leading-[22px]">
        <div className="mb-[30px] p-2 md:p-10">{content}</div>
        <div className="flex">
          {cancelActionTitle && (
            <Button variant="white" className="mr-[20px]" type="button" onClick={cancelAction}>
              {cancelActionTitle}
            </Button>
          )}
          {actionTitle && (
            <Button
              form={actionForm}
              type={actionForm ? 'submit' : 'button'}
              variant="primary"
              onClick={onActionClick}
            >
              {actionTitle}
            </Button>
          )}
        </div>
      </div>
    </CleanModal>
  );
};
