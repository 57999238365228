import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import { ErrorMessage } from '@components/error-message';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const DATE_FORMAT = 'YYYY-MM-DD';

export const BirthDate = () => {
  // if in a form context
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const defaultDate = getValues('dateOfBirth')
    ? dayjs(getValues('dateOfBirth'), DATE_FORMAT)
    : null;

  const handleDateChange = (value: dayjs.Dayjs) => {
    if (!value?.isValid()) return;
    setValue('dateOfBirth', value?.format(DATE_FORMAT), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    register('dateOfBirth', {
      required: 'Date of birth is required.',
    });
  }, [register]);

  const errorInvalid = errors && errors?.['dateOfBirth']?.message;

  return (
    <div className="flex flex-col gap-2">
      <label>Date of Birth</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={
            errorInvalid
              ? {
                  outline: '.6px solid red ',
                  borderRadius: '4px',
                }
              : {}
          }
          maxDate={dayjs().subtract(1, 'day')}
          defaultValue={defaultDate}
          value={defaultDate}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
      {errorInvalid && (
        <ErrorMessage error={errors?.['dateOfBirth']?.message as string} className="text-xs " />
      )}
    </div>
  );
};

{
  /* <Input
        label='Date Of Birth'
        placeholder='Enter your date of birth'
        className='w-full flex'
        name='dateofbirth'
        /> */
}
