import { AxiosResponse } from 'axios';
import { FC, ReactNode } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getNotPoppedRewards, markPoppedRewards } from '@services/Api/controllers/rewards';

/**
 * use this hook to refetch the pop up rewards
 * as the rewards are awarded in the backend
 * we can call the returned function where we might complete a event.
 * if the award is completed in time then we would get the current rewards
 *  thus the problem of the new rewards not showing might get solved.
 * @returns function
 */
export const useRefetchRewards = () => {
  const queryClient = useQueryClient();
  return async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    queryClient.invalidateQueries(['not-popped-rewards']);
  };
};

const RewardsPopUpWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const onSuccess: (
    data: AxiosResponse<{
      items: Array<{
        id: number;
        points: number;
        hasPoppedUp: boolean;
        reward: {
          id: string;
          name: string;
          description: string;
          imageUrl: string;
        };
      }>;
    }>,
  ) => Promise<void> = async (data) => {
    const { items } = data.data;
    // items.forEach((item) => {
    //   toast.success(`You have earned ${item.points} points for ${item.reward.name} reward.`, {
    //     position: 'bottom-center',
    //     duration: 3000,
    //   });
    // });
    if (items.length)
      await mutateAsync({
        data: {
          rewardsIds: items.map((e) => e.id),
        },
      });
  };

  const { mutateAsync } = useMutation({
    mutationFn: ({
      data,
    }: {
      data: {
        rewardsIds: number[];
      };
    }) => markPoppedRewards({ data }),
    mutationKey: ['mark-popped-rewards'],
  });
  useQuery({
    queryFn: () => getNotPoppedRewards(),
    queryKey: ['not-popped-rewards'],
    onSuccess,
  });

  return <div >{children}</div>;
};

export default RewardsPopUpWrapper;
