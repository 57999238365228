import Image from "next/image";
import { ReactNode } from 'react';

interface IntroductionStepProps {
  title: ReactNode;
  description: ReactNode;
  image: string;
  priority?: boolean;
}

export const IntroductionStep = ({
  title,
  description,
  image,
  priority,
}: IntroductionStepProps) => {
  return (
    (<div className="relative flex flex-col lg:bg-gray-50 lg:mx-auto lg:max-w-[600px]">
      <div className="overflow-hidden image-container relative  mix-blend-multiply">
        <Image
          className="flex w-full object-contain max-h-[inherit]"
          src={image}
          alt=""
          priority={priority}
          fill
          width={100}
          height={100}
          sizes="100vw" />
      </div>
      <div className="container-default lg:text-center">
        <h1 className="text-2xl font-medium font-slab">{title} </h1>
        <div className="flex flex-col gap-12 mt-4">
          <p className="text-sm leading-6 text-gray-600">{description}</p>
        </div>
      </div>
      <style jsx>
        {`
          .image-container {
            min-height: calc(20vh + 50px);
            margin-bottom: 24px;
          }

          @media (min-height: 600px) {
            .image-container {
              min-height: calc(30vh + 50px);
              margin-bottom: 24px;
            }
          }

          @media (min-height: 750px) {
            .image-container {
              min-height: 375px;
              margin-bottom: 60px;
            }
          }

          @media (min-width: 992px) and (min-height: 600px) {
            .image-container {
              margin-top: -30px;
              min-height: 350px;
              margin-bottom: 40px;
            }
          }

          @media (min-width: 992px) and (min-height: 800px) {
            .image-container {
              min-height: 400px;
              margin-bottom: 40px;
            }
          }
        `}
      </style>
    </div>)
  );
};
