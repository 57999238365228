import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Image from "next/image";
import { useRouter } from 'next/router';
import { commonRoutes } from '@utils';

export const BackButtonComponent = () => {
  const router = useRouter();

  const isOnboardingRoute = router.pathname === commonRoutes.buyer.auth.onboarding;
  const isQuickSetupRoute = router.pathname === '/user/onboarding/quick-setup';
  const isCompleteSetupRoute = router.pathname === commonRoutes.buyer.onboarding.completeSetup;
  const isLinkBankAccountRoute = router.pathname === commonRoutes.buyer.onboarding.linkBankAccount;
  const isVerifyIncomeRoute = router.pathname === commonRoutes.buyer.onboarding.verifyIncome;
  const isGenerateCreditRoute = router.pathname === commonRoutes.buyer.onboarding.generateCredit;

  const handleBack = () => {
    if (isCompleteSetupRoute || isQuickSetupRoute) {
      router.push(commonRoutes.buyer.auth.onboarding);
    } else if (isLinkBankAccountRoute) {
      router.push(commonRoutes.buyer.onboarding.completeSetup);
    } else if (isVerifyIncomeRoute) {
      router.push(commonRoutes.buyer.onboarding.linkBankAccount);
    } else if (isGenerateCreditRoute) {
      router.push(commonRoutes.buyer.onboarding.verifyIncome);
    }
  };

  return (
    <Box
      className="back-button-area"
      sx={{
        backgroundColor: '#fff',
        padding: '14.4px',
        borderRadius: '14.4px',
        display: 'flex',
        gap: '14.4px',
        width: '163px',
        cursor: 'pointer',
      }}
      onClick={handleBack}
    >
      {!isOnboardingRoute && (
        <Box
          display="flex"
          sx={{
            padding: '6px',
            color: '#fff',
            border: '1px solid #000',
            width: '36.48px',
            height: '36.48px',
            borderRadius: '11.52px',
          }}
        >
          <ArrowBackIcon style={{ color: 'black' }} />
        </Box>
      )}
      <img src="/images/MHP_Lockup_Mint.png" alt="MHP logo" width="78" height="33.6" />
    </Box>
  );
};
