import { useAuthUser, useIsMounted } from '@hooks';
import { Toast, commonRoutes, extractNumbers } from '@utils';
import { Form } from '@components/form';
import { Loading } from '@components/loading';
import { SettingsContent, SettingsSave } from '@components/settings/settings-content';
import { CountryCode } from 'libphonenumber-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ApiService, BuyerProfile, EMonthlyRent, WishedLocation } from '@services/Api';
import { SettingsDesktopLayout } from '../settings-desktop-layout';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { BuyerLayout } from '@components/layout/buyer-layout';
import { DashboardBreakdown } from '@components/summary/dashboard/dashboard-breakdown';
export type SettingModal = 'profile' | 'accounts' | 'feedback' | 'income';

export const useSettingsModal = () => {
  const router = useRouter();
  const setSettingsModalOpen = async (currentSettingsModal: SettingModal | null) => {
    await router.push(
      currentSettingsModal === 'profile'
        ? commonRoutes.buyer.auth.account
        : currentSettingsModal
        ? commonRoutes.buyer[currentSettingsModal]
        : commonRoutes.buyer.auth.settings,
    );
  };

  return { setSettingsModalOpen };
};

export const SettingsProfile = () => {
  const { profile, isLoadingUserInfo, refetchUserInfo } = useAuthUser();
  const isMounted = useIsMounted();

  //Testing global keypress event
  useEffect(() => {
    const handleKeyPress = (e) => {};

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);
  const methods = useForm<
    Omit<BuyerProfile, 'children' | 'hispanicOrigin' | 'coBorrower'> & {
      countryCode: CountryCode;
      children: 'yes' | 'no';
      hispanicOrigin?: 'yes' | 'no';
      locationsSearch: string;
      dateOfBirth: string;
      accessCode?: string;
      avatar?: string;
      coBorrower?: 'yes' | 'no';
      coBorrowersFirstName?: string;
      coBorrowersLastName?: string;
      coBorrowersEmail?: string;
    }
  >({
    mode: 'onChange',
    defaultValues: {
      email: profile?.email,
      firstName: profile?.firstName,
      accessCode: profile?.accessCode,
      avatar: profile?.avatar,
      lastName: profile?.lastName,
      phone: profile?.phone || '',
      occupation: profile?.occupation,
      reasonForPurchase: profile?.reasonForPurchase,
      gender: profile?.gender,
      race: profile?.race,
      hispanicOrigin: profile?.hispanicOrigin ? 'yes' : 'no',
      children: profile?.children ? 'yes' : 'no',
      childrenUnder18Count: profile?.childrenUnder18Count,
      wishedLocations: profile?.wishedLocations,
      street: profile?.street,
      apartment: profile?.apartment,
      city: profile?.city,
      militaryStatus: profile?.militaryStatus,
      state: profile?.state,
      zipCode: profile?.zipCode,
      locationsSearch: '',
      dateOfBirth: profile?.dateOfBirth,
      allowedEmails: profile?.allowedEmails || [],
      allowedSms: profile?.allowedSms || [],
      coBorrower: profile?.coBorrower ? 'yes' : 'no',
      coBorrowersFirstName: profile?.coBorrowersFirstName,
      coBorrowersLastName: profile?.coBorrowersLastName,
      coBorrowersEmail: profile?.coBorrowersEmail,
      houseRent: profile?.houseRent,
    },
  });

  const updateProfileMutation = useMutation(
    async (values: Partial<BuyerProfile>) => {
      const { data } = await ApiService.updateBuyer(values);
      return data;
    },
    {
      onSuccess: async ({ profile }) => {
        await refetchUserInfo();
        await router.push(commonRoutes.buyer.auth.account);
        Toast({ variant: 'primary', text: 'Profile updated' });
        methods.reset({
          email: profile?.email,
          firstName: profile?.firstName,
          accessCode: profile?.accessCode,
          avatar: profile?.avatar,
          lastName: profile?.lastName,
          phone: profile?.phone,
          occupation: profile?.occupation,
          reasonForPurchase: profile?.reasonForPurchase,
          gender: profile?.gender,
          race: profile?.race,
          hispanicOrigin: profile?.hispanicOrigin ? 'yes' : 'no',
          children: profile?.children ? 'yes' : 'no',
          childrenUnder18Count: profile?.childrenUnder18Count,
          wishedLocations: profile?.wishedLocations,
          street: profile?.street,
          apartment: profile?.apartment,
          city: profile?.city,
          militaryStatus: profile?.militaryStatus,
          state: profile?.state,
          zipCode: profile?.zipCode,
          locationsSearch: '',
          dateOfBirth: profile?.dateOfBirth,
          allowedEmails: profile?.allowedEmails || [],
          allowedSms: profile?.allowedSms || [],
          coBorrower: profile?.coBorrower ? 'yes' : 'no',
          coBorrowersFirstName: profile?.coBorrowersFirstName,
          coBorrowersLastName: profile?.coBorrowersLastName,
          coBorrowersEmail: profile?.coBorrowersEmail,
          houseRent: profile?.houseRent,
        });
      },
    },
  );

  const onSubmit = methods.handleSubmit(
    (
      fields: Omit<BuyerProfile, 'children' | 'hispanicOrigin'> & {
        countryCode: CountryCode;
        children: 'yes' | 'no';
        hispanicOrigin?: 'yes' | 'no';
        locationsSearch: string;
        accessCode?: string;
        avatar?: string;
        coBorrower?: boolean;
        coBorrowersFirstName?: string;
        coBorrowersLastName?: string;
        coBorrowersEmail?: string;
        houseRent?: EMonthlyRent;
      },
    ) => {
      const children = fields.children === 'yes';
      const hispanicOrigin = fields.hispanicOrigin === 'yes';

      const childrenUnder18Count = children ? fields.childrenUnder18Count : null;

      const phone =
        Number(extractNumbers(fields.phone)) > 1 ? `${extractNumbers(fields.phone)}` : null;

      const wishedLocations: WishedLocation[] = fields.wishedLocations.map((location) => {
        if (typeof location === 'string') {
          return {
            country: 'United States',
            state: location,
          };
        }

        return {
          country: 'United States',
          state: location.state,
          city: location.city,
        };
      });

      const values = {
        firstName: fields.firstName,
        lastName: fields.lastName,
        gender: fields.gender,
        race: fields.race,
        hispanicOrigin,
        avatar: fields.avatar || null,
        children,
        childrenUnder18Count,
        dateOfBirth: fields.dateOfBirth,
        wishedLocations,
        street: fields.street,
        apartment: fields.apartment,
        city: fields.city,
        state: fields.state,
        zipCode: fields.zipCode,
        militaryStatus: fields.militaryStatus,
        occupation: fields.occupation,
        reasonForPurchase: fields.reasonForPurchase,
        phone,
        allowedEmails: fields.allowedEmails,
        allowedSms: fields.allowedSms,
        coBorrower: fields.coBorrower,
        coBorrowersFirstName: fields.coBorrowersFirstName,
        coBorrowersLastName: fields.coBorrowersLastName,
        coBorrowersEmail: fields.coBorrowersEmail,
        houseRent: fields.houseRent,
      };

      updateProfileMutation.mutate(values);
    },
  );

  const router = useRouter();
  const formId = 'settings-form';
  return (
    isMounted && (
      <>
        <Loading isLoading={isLoadingUserInfo || updateProfileMutation?.isLoading} />

        <Form
          id={formId}
          onSubmit={(e) => {
            const target = e.target as { id?: string };
            const id = target?.id;
            e.preventDefault();
            e.stopPropagation();
            if (id === formId) {
              onSubmit(e);
            }
          }}
          methods={methods}
        >
          <SettingsDesktopLayout>
            <SettingTabs />
            {/* <SettingsContent /> */}
          </SettingsDesktopLayout>
          <SettingsSave />
        </Form>
      </>
    )
  );
};

export const SettingTabs = () => {
  const user = useAuthUser();
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
        sx={{
          '& .MuiTab-root': {
            color: '#635E7D', 
          },
          '& .Mui-selected': {
            color: '#65C29C', 
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#65C29C', 
          },
        }}
        >
          <Tab className="" label="Profile" {...a11yProps(0)} />
          <Tab label="Next Steps" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SettingsContent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DashboardBreakdown
          userHousePriceGoal={user?.profile?.housePriceGoal}
          expectedPurchaseMonthsPeriod={user?.profile?.expectedPurchaseMonthsPeriod}
        />
      </CustomTabPanel>
    </Box>
  );
};
